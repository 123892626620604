import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

import { PostServerData, UseServerData } from "../../util/dbfetch";
import RegisterOrganization from "../organization/registerOrganization";
import useSnackbarContext from '../../util/providers/snackbarProvider';

export function CreateProject() {
  const [organizations, setOrganizations] = useState([]);

  const [users, setUsers] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const { openSnackbar } = useSnackbarContext();

  const { control, handleSubmit, register, formState: { errors }, watch } = useForm({
    defaultValues: {
      proj_name: "",
      proj_desc: "",
      utility: "",
      organizationid: "",
      organization_viewer: true,
      editors: [""],
      viewers: [""],
    },
  });

  const navigate = useNavigate();

  const watchedOrganizationid = watch('organizationid');
  const watchedOrgView = watch('organization_viewer');
  // const watchedEditors = watch('editors');

  const utilityOptions = [
    { value: 'gen_e', label: 'Electric(general)' },
    { value: 'gen_g', label: 'Gas(general)', isDisabled: true },
  ];

  const setOrganizationsCallback = useCallback((data) => {
    setOrganizations(data);
  }, [setOrganizations]);

  useEffect(() => {
    UseServerData("organizations", (data) => setOrganizationsCallback(data), () => { }, (err) => { })
  }, [setOrganizationsCallback]);


  useEffect(() => {
    if (watchedOrganizationid) {
      const orgfound = organizations.find((org) => org.organizationid === watchedOrganizationid);
      setUsers(orgfound.members);
    }
  }, [watchedOrganizationid, organizations]);



  function onSubmit(data) {
    // for utility, project_lead, editors, and viewers, convert from value/label pairs to just value
    PostServerData("project/new", data, handleProjPostSuccess, (err) => openSnackbar(err));
  }

  function handleProjPostSuccess(resp) {
    // succeeded, now navigate to the project page
    if (resp.data.projectid) {
      // navigate to the project page
      navigate(`/project/${resp.data.projectid}`);
    } else {
      // navigate to the project list page
      navigate(`/project`);
    }
    openSnackbar("Project Created Successfully")
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = (resData) => {
    setDialogOpen(false);
    if (resData.data) {
      setOrganizations([resData.data, ...organizations])
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="proj_name"
          label="Project Name"
          placeholder="Project Name"
          fullWidth
          margin="normal"
          {...register("proj_name", { required: "This is required", minLength: { value: 3, message: "This is too short" }, maxLength: { value: 30, message: "Too Long (30 char max)" } })}
          error={!!errors.proj_name}
          helperText={errors.proj_name?.message}
        />

        <TextField
          name="proj_desc"
          label="Project Description"
          placeholder="Project Description"
          fullWidth
          margin="normal"
          multiline
          minRows={3}
          maxRows={5}
          {...register("proj_desc", { required: "This is required", maxLength: { value: 255, message: "Too Long (255 char max)" } })}
          error={!!errors.proj_desc}
          helperText={errors.proj_desc?.message}
        />

        <FormControl fullWidth margin="normal" error={!!errors.utility}>
          <InputLabel id="utility-label">Utility</InputLabel>
          <Controller
            name="utility"
            control={control}
            render={({ field }) => (
              <Select
                labelId="utility-label"
                id="utility"
                {...field}
                {...register("utility", { required: true })}
              >
                {utilityOptions.map((util) => (
                  <MenuItem key={util.value} value={util.value}>{util.label}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.utility && <FormHelperText>{errors.utility.message}</FormHelperText>}
        </FormControl>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="organization-label">Organization</InputLabel>
            <Controller
              name="organization"
              control={control}
              value={watchedOrganizationid}
              render={({ field }) => (
                <>
                  {organizations.length > 0 ? (
                    <Select
                      labelId="organization-label"
                      id="organization"
                      {...field}
                      {...register("organizationid", { required: true })}
                    >
                      {organizations.map((org) => (
                        <MenuItem key={org.organizationid} value={org.organizationid}>
                          {org.organization_name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <CircularProgress />
                  )}
                </>
              )}
            />
          </FormControl>

          <Button variant="contained" onClick={() => handleOpenDialog(<RegisterOrganization onClose={handleCloseDialog} />)}>
            Register Organization
          </Button>
          <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <Box sx={{ p: 4 }}>{dialogContent}</Box>
          </Dialog>
        </Box>

        <FormControl fullWidth margin="normal">
          <InputLabel id="editors-label">Editors</InputLabel>
          <Controller
            name="editors"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                labelId="editors-label"
                id="editors"
                onChange={onChange}
                value={value}
                multiple
              >
                {users.map((user) => (
                  <MenuItem key={user.userid} value={user.userid}>{user.username}</MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox {...register('organization_viewer')} checked={watch('organization_viewer')} />
          }
          label="All Organization Members Can View"
        />
        <Tooltip title="Specific Persons to View">
          <FormControl fullWidth margin="normal">
            <InputLabel id="viewers-label">Viewers</InputLabel>
            <Controller
              name="viewers"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="viewers-label"
                  id="viewers"
                  disabled={!watchedOrgView}
                  {...field}
                  multiple
                >
                  {users.map((user) => (
                    <MenuItem key={user.userid} value={user.userid}>{user.username}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Tooltip>

        <Button type="submit" variant="contained" color="primary">Create Project</Button>
      </form>
    </Box>
  );
}
