import React, { useEffect, useState, useReducer } from 'react';
import {
  useNavigate, useParams
} from "react-router-dom"
import Button from '@mui/material/Button';

import { UseServerData } from "../../util/dbfetch";
import { stateReducer, STATE_ACTION } from '../stateReducer';

import { useTutorial } from '../../util/providers/useTutorialContext';
import ProjectHeader from './projectHeader';
import InstanceLister from './instanceListItem';

export function ProjectDetails() {
  const { projID } = useParams();
  const navigate = useNavigate();

  // const [projectData, setProjectData] = useState({});
  const [projectData, dispatch] = useReducer(stateReducer, {});
  const [loadingProject, setLoadingProject] = useState(true);

  const [instances, setInstances] = useState([]);
  const [loadingInstance, setLoadingInstance] = useState(true);
  const [errorInstance, setErrorInstance] = useState("");
  const [latestInstance, setLatestInstance] = useState(null);

  const { handleProgress } = useTutorial();

  const setProjectData = (data) => {
    dispatch({
      type: STATE_ACTION.LOAD_INITIAL,
      payload: data,
    });
  };

  // Project Details
  useEffect(() => {
    UseServerData(`project/${projID}`, setProjectData, () => setLoadingProject(false), (resp) => { });
  }, [projID]);

  // Project Instances
  useEffect(() => {
    UseServerData(`project/${projID}/instances`, setInstances, setLoadingInstance, setErrorInstance);
  }, [projID]);


  useEffect(() => {
    if (instances.length > 0) {
      const sortedInstances = instances.sort((a, b) => new Date(b.date_update) - new Date(a.date_update));
      setLatestInstance(sortedInstances[0]);
    }
  }, [instances]);

  function handleEditLocations(event) {
    if (Object.keys(instances).length === 0) {
      navigate(`/project/${projID}/instance/edit`)
    } else if (latestInstance) {
      navigate(`/project/${latestInstance.projectid}/instance/edit?instanceid=${latestInstance.instanceid}`);
    }

    if (latestInstance) {
      // Do we need to use the instanceid in the tutorial?
      handleProgress(event.target.className, { instanceid: latestInstance.instanceid })
    }
  }

  return (
    <>
      {loadingProject ? (
        <div>Loading project...</div>
      ) : (
        <>
          <ProjectHeader projectData={projectData} onDispatch={dispatch} />
          <Button className="tutorial-project-rfp-final tutorial-project-rfp-intro" variant="outlined"
            onClick={(event) => {
              navigate(`/project/${projID}/rfp`);
              handleProgress(event.target.className);
            }}
          >Open Requests for Proposals</ Button>
        </>
      )}

      <br />
      <Button className="tutorial-current-locations-button current-locations-button"
        variant="contained" onClick={handleEditLocations}>Edit Current Locations</Button>
      <Button
        className="tutorial-project-price-profiles"
        variant="outlined"
        disabled={Object.keys(instances).length === 0}
        onClick={(event) => {
          navigate(`/project/${projID}/price-profile?instanceid=${latestInstance.instanceid}`)
          handleProgress(event.target.className);
        }}
      >View Price Profile</Button>
      {loadingInstance ? (
        <div>Loading instances...</div>

      ) : errorInstance ? (
        <div>{errorInstance}</div>
      ) : (
        <InstanceLister
          projID={projID}
          instances={instances}
          setInstances={setInstances}
        />
      )}
    </>
  );
};