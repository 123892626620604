import { useState, useContext } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
import Cookies from 'js-cookie';

import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// import { AccountBalance } from "@mui/icons-material";
import AccountBalance from '@mui/icons-material/AccountBalance';

import ViewListIcon from '@mui/icons-material/ViewList';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useTutorial } from '../../util/providers/useTutorialContext';
import useUserDataContext from '../../util/providers/userDataProvider.jsx';

import { ThemeContext } from "../../theme/themeManager";

import ConfirmDialog from '../../components/confirmDialog';


export default function RouteHeader({ onLogout }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { mode, toggleColorMode } = useContext(ThemeContext);

  const { handleProgress } = useTutorial();
  const { user: userData, refreshData } = useUserDataContext();



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (orgid) => {
    navigate(`/organizations/${orgid}`)
    setAnchorEl(null);
    // navigate('/organizations');
  };
  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  function handleLogout() {
    handleOpenDialog(
      <ConfirmDialog
        message="Are you sure you want to log out?"
        onConfirm={() => {
          Cookies.remove('jwtToken');
          onLogout();
          refreshData();
          setDialogOpen(false);
          navigate('/');
        }}
        onCancel={() => setDialogOpen(false)}
      />
    )

  }

  function handleHome(event) {
    navigate("/");
    handleProgress(event.target.className);


  }


  const orgListLinks = userData?.organizations?.map((org) => (

    <MenuItem key={org.organizationid} onClick={() => handleClose(org.organizationid)}>
      {org.organization_name}
    </MenuItem>
  ));

  // Split the pathname to get each segment
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Create an array of link objects
  const links = pathnames.map((name, index) => ({
    name: name,
    path: `/${pathnames.slice(0, index + 1).join("/")}`,
  }));

  return (
    <Box sx={{ pb: 2 }}>
      <Dialog maxWidth="md" open={dialogOpen} onClose={handleCloseDialog}>
        <Box sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: { sm: 'default', md: 'flex' }, alignItems: 'flex-end' }}>
          <Typography variant="h1">Utility Design App</Typography>
          <IconButton
            sx={{ ml: 2 }}
            onClick={() => {
              toggleColorMode();
            }}>
            {mode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {/* 
          Temporarily disable this Link; write more here about the app
          
          <Link 
              variant="sub1"
              underline="hover" 
              color="textPrimary" 
              component={RouterLink} 
              to="/about">
            About this app
          </Link> */}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <>
            <Link
              underline="hover"
              color="textPrimary"
              component={RouterLink}
              to="/project"
            >
              <IconButton title="View Projects">
                <ViewListIcon />
              </IconButton>
            </Link>
            <IconButton onClick={handleClick} title="View Organization Accounts">
              <AccountBalance />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {orgListLinks}
            </Menu>
            <Link
              underline="hover"
              color="textPrimary"
              component={RouterLink}
              to="/user"
            >
              <IconButton title="View User Profile" >
                <PersonIcon />
              </IconButton>
            </Link>
            <Link underline="hover" color="textPrimary" onClick={handleLogout}>
              <IconButton title="Log out">
                <LogoutIcon />
              </IconButton>
            </Link>
          </>
        </Box>
      </Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Button
          className="introduction-header-progress"
          color="primary"
          onClick={handleHome}
          variant="text"
        >
          Home
        </Button>
        {links.map((link) => (
          <Link
            key={link.path}
            underline="hover"
            color="textPrimary"
            component={RouterLink}
            to={link.path}
          >
            {link.name}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}