import { useState, useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom"

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { ThemeContext } from "../../theme/themeManager";
import Login from "../user/login";
import Register from "../user/registerUser";


export default function RouteHeaderVisitor({ onLogin }) {
  const location = useLocation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const { mode, toggleColorMode } = useContext(ThemeContext);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  // Split the pathname to get each segment
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Create an array of link objects
  const links = pathnames.map((name, index) => ({
    name: name,
    path: `/${pathnames.slice(0, index + 1).join("/")}`,
  }));

  return (
    <Box sx={{ pb: 2 }}>
      <Dialog maxWidth="md" open={dialogOpen} onClose={handleCloseDialog}>
        <Box sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: { sm: 'default', md: 'flex' }, alignItems: 'flex-end' }}>
          <Typography variant="h1">Utility Design App</Typography>
          <IconButton
            sx={{ ml: 2 }}
            onClick={() => {
              toggleColorMode();
            }}>
            {mode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {/* 
          Temporarily disable this Link; write more here about the app
          
          <Link 
              variant="sub1"
              underline="hover" 
              color="textPrimary" 
              component={RouterLink} 
              to="/about">
            About this app
          </Link> */}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <>
            <Link underline="hover" color="textPrimary" onClick={() => handleOpenDialog(<Register onClose={handleCloseDialog} />)}>
              <IconButton title="Register">
                <PersonAddIcon />
              </IconButton>
            </Link>
            <Link underline="hover" color="textPrimary" onClick={() => handleOpenDialog(<Login onLogin={onLogin} onClose={handleCloseDialog} />)}>
              <IconButton title="Log In">
                <LoginIcon />
              </IconButton>
            </Link>
          </>
        </Box>
      </Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="textPrimary"
          component={RouterLink}
          to="/"
        >
          Home
        </Link>
        {links.map((link) => (
          <Link
            key={link.path}
            underline="hover"
            color="textPrimary"
            component={RouterLink}
            to={link.path}
          >
            {link.name}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}