
import { Route, Routes } from "react-router-dom";

import PageNotFound from '../pageNotFound';
import { ProjectIndex } from '../../components/managementItems/projectIndex';
import { ProjectDetails } from "../../components/managementItems/projectDetailer";
import { CreateProject } from './createProject';
import ProjectPriceProfile from './projectPriceProfile';
import { InstanceLocations } from '../../components/managementItems/instances';
import LocationOptionSelector from './locationOptionSelector';
import FindOrganization from '../../components/transfers/findOrganization';


export function ProjectRoute() {
  // root/project

  return (
    <Routes>
      <Route index element = {<ProjectIndex />} />
      <Route path = "new" element = {<CreateProject />} />
      <Route path = ":projID" element = {<ProjectDetails />} />
      <Route path = ":projID/rfp" element = {<FindOrganization />} />
      <Route path = ":projID/price-profile" element = {<ProjectPriceProfile />} />
      <Route path = ":projID/instance/edit" element = {<InstanceLocations />} />
      <Route path = ":projID/instance/:instanceID/choices" element = {<LocationOptionSelector />} />
      <Route path = "*" element = {<PageNotFound />} />
    </Routes>
    )
}

