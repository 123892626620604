import { useState } from 'react';

import 'react-calendar/dist/Calendar.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import { PostServerData } from '../../util/dbfetch';
import useSnackbarContext from '../../util/providers/snackbarProvider';

export default function TransferResponsibilityConfirm({ onCancel, organizationTargetid, projects, expiration, useCount, userverify }) {

  const [hyperLink, setHyperLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);

  const { openSnackbar } = useSnackbarContext();

  const intrusiveResponsib = projects.filter((project) => project.roles.includes('sponsor') || project.roles.includes('designer'));

  function handleGenerateLink() {
    if (organizationTargetid === null) {

    } else if (organizationTargetid === "") {
      openSnackbar("Error Detected: Organization selection is required");
      return;
    }

    setLoading(true);
    setGenerated(true);
    PostServerData("generate-transfer-link/", { organizationTargetid, projects, expiration, useCount, userverify },
      (link) => { setHyperLink(link.data); setLoading(false); openSnackbar("Link Generated"); },
      (err) => { openSnackbar(err); });
  }

  if (organizationTargetid && intrusiveResponsib.length > 0) {
    return (
      <Paper sx={{ my: 2, p: 1 }}>
        <Typography>
          This is an invalid invite. You cannot invite to your organization while transfering Sponsor or Designer roles.
          You may only use Editor and Viewer roles.
        </Typography>
      </Paper>
    );
  }


  return (
    <>
      {organizationTargetid &&
        <Paper sx={{ my: 2, p: 1 }}>
          <Typography>
            You are generating a link to invite other users to your organization as members.
            This generated code can also be used create a new account.
          </Typography>
        </Paper>}


      {projects.length > 0 && (
        <Paper sx={{ my: 2, p: 1 }}>
          <Typography>
            You will be giving access to the following projects:
          </Typography>
          <Box style={{ display: 'flex' }}>
            {projects.map((project) => (
              <Typography key={project.projectid} style={{ marginRight: '10px' }}>
                {project.proj_name}
              </Typography>
            ))}
          </Box>
        </Paper>
      )}


      {intrusiveResponsib.length > 0 && (
        <Paper sx={{ my: 2, p: 1 }}>
          <Typography>
            You will lose some or all direct have control over the following projects:
          </Typography>
          <List>
            {intrusiveResponsib.map((project) => (
              <ListItem key={project.projectid}>
                <ListItemText primary={project.proj_name} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {userverify ? (
        <Paper sx={{ my: 2, p: 1 }}>
          <Typography>
            After your recipient has accepted the transfer, you will need to confirm them on any of the above projects.
          </Typography>
        </Paper>
      ) : intrusiveResponsib.length > 0 ? (
        <Paper sx={{ my: 2, p: 1 }}>
          <Typography>
            The recipient will immediately gain control over the above projects.
          </Typography>
        </Paper>
      ) : null}

      <Typography>
        By clicking "Confirm" you will generate a unique code to claim the projects listed above and/or join the organization.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleGenerateLink} disabled={loading || generated}>
          Confirm
        </Button>
      </Box>

      {hyperLink && (
        <TextField
          label="Generated Hyperlink"
          value={hyperLink}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
      )}
    </>
  );
}