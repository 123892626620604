import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';

import AlignmentEditor from '../alignmentEditor';

import useSnackbarContext from '../../../util/providers/snackbarProvider.jsx';
import { useTutorial } from '../../../util/providers/useTutorialContext.jsx';

import { Alignment, AlignmentConduitOffset, AlignmentItemized } from '../../../models/alignment.ts';
import {
  AlignmentPerpendicular,

} from '../../../models/alignmentPerpendicular.ts';

import { combineAndOrderPoints } from '../../../util/geoSpatial.ts';

// Constants
const units = [
  { value: 'feet', label: 'ft.' },
  { value: 'meters', label: 'm.' },
];

const bearingModes = [
  { value: 'bisect', label: 'Bisect' },
  { value: 'headAngle', label: 'Head Angle' },
  { value: 'tailAngle', label: 'Tail Angle' },
];

/**
 * AlignmentMaker is a component that allows users to create, update, and delete alignments.
 * TODO: Extend this renderer to check against besides alignment, LocationArray, and selectedObjects.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.className - The CSS class name for the component.
 * @param {Alignment[]} props.alignment - An array of Alignment objects.
 * @param {Function} props.setAlignment - A function to set the current alignment.
 * @param {Array} props.LocationArray - An array of location data.
 * @param {Array} props.selectedObjects - An array of selected objects.
 *
 * @returns {JSX.Element} A component that allows users to interact with alignments.
 */
export default function AlignmentMaker({
  className,
  alignment,
  setAlignment,
  LocationArray,
  selectedObjects,
  onDeselectObjects,
  handleOpenDialog,
  mapRef
}) {
  const [renderedName, setRenderedName] = useState('default');
  const [alignmentName, setAlignmentName] = useState('default');

  const [width, setWidth] = useState(20);  // always feet
  const [renderedWidth, setRenderedWidth] = useState(20);  // feet or meters, converted from width
  const [bearingMode, setBearingMode] = useState('headAngle'); // head, tail, or bisect
  const [bearingModeIndex, setBearingModeIndex] = useState(0);

  const [conduitOffset, setConduitOffset] = useState(0);

  const [currAlignment, setCurrAlignment] = useState(new Alignment([], alignmentName));
  const [unitIndex, setUnitIndex] = useState(0); // Initialize with the index of the default unit

  const { openSnackbar } = useSnackbarContext();
  const { handleProgress } = useTutorial();

  const filter = createFilterOptions();

  /**
   * This useEffect hook updates the current alignment when the alignmentName or width changes.
   */
  useEffect(() => {
    const existingAlignment = alignment.find(align => align.name === alignmentName);
    const centerLinePoints = existingAlignment ? existingAlignment.points : [];

    setCurrAlignment(new AlignmentConduitOffset(centerLinePoints, alignmentName, null, conduitOffset));
  }, [alignmentName, width, conduitOffset, alignment]);

  /**
   * This useEffect hook updates the width when the unitIndex or renderedWidth changes.
   */
  useEffect(() => {
    if (units[unitIndex].value === 'meters') {
      setWidth(renderedWidth * 3.281);
    } else if (units[unitIndex].value === 'feet') {
      setWidth(renderedWidth);
    }

  }, [unitIndex, renderedWidth]);

  useEffect(() => {
    setBearingMode(bearingModes[bearingModeIndex].value);
  }, [bearingModeIndex]);

  /**
   * This function returns an array of AlignmentPerpendicular objects from the selectedObjects array.
   * TODO: this should be handled externally, not in this component
   *
   * @param {Array} newObjects - An array of new objects.
   * @returns {AlignmentPerpendicular[]} An array of AlignmentPerpendicular objects.
   */
  const getCenterlineData = (newObjects) => {
    const pointArrays = newObjects
      .filter(obj => obj.type === 'way' && obj?.geometry && obj?.nodes)
      .map(obj => {
        return obj.geometry.map((point, index) => {
          const nodeId = obj.nodes[index];
          return new AlignmentPerpendicular(
            point.lat,
            point.lon,
            width,
            bearingMode,
            null,
            nodeId,
          );
        });
      })

    // for each list of perpendiculars I should find the adjacent points
    // and combine them into a single list one list by one list

    const combList = combineAndOrderPoints(pointArrays);

    return combList;
  };

  const genTempCenterLine = () => {
    const bounds = mapRef.current.getBounds();

    const bottomLeft = bounds.getSouthWest();
    const topRight = bounds.getNorthEast();

    const thirdLat = (topRight.lat - bottomLeft.lat) / 3;
    const thirdLng = (topRight.lng - bottomLeft.lng) / 3;

    const point1 = [bottomLeft.lat + thirdLat, bottomLeft.lng + thirdLng];
    const point2 = [topRight.lat - thirdLat, topRight.lng - thirdLng];

    const alignmentPerpendicular1 = new AlignmentPerpendicular(
      point1[0],
      point1[1],
      width,
      bearingMode,
      null,
      null
    );

    const alignmentPerpendicular2 = new AlignmentPerpendicular(
      point2[0],
      point2[1],
      width,
      bearingMode,
      null,
      null
    );

    return [alignmentPerpendicular1, alignmentPerpendicular2];
  };

  // Event method

  const handleUpdateAlignment = (event) => {
    const newAlignments = getCenterlineData(selectedObjects);

    if (newAlignments.length > 0) {
      setAlignment(prevAlignment => {
        const existingAlignIndex = prevAlignment.findIndex(align => align.name === alignmentName);
        if (existingAlignIndex !== -1) {
          const updatedAlignment = prevAlignment[existingAlignIndex].addPoints(newAlignments);
          prevAlignment[existingAlignIndex] = updatedAlignment;
        } else {
          // Create a new alignment
          const newAlignment = new AlignmentConduitOffset(newAlignments, alignmentName, null, conduitOffset);
          prevAlignment.push(newAlignment);
        }
        openSnackbar(`Alignment  ${alignmentName} updated.`)

        onDeselectObjects();
        return [...prevAlignment];
      });
      handleProgress(event.target.className);
    } else {
      openSnackbar('Select a road to add to an alignment.')
    }


  };

  const handleDeleteAlignment = () => {
    setAlignment(prevAlignment => prevAlignment.filter(align => align.name !== alignmentName));
    setAlignmentName('default');
    setRenderedName('default');

  };

  const handleWidthChange = (event) => {
    let widthInFeet = Number(event.target.value);
    if (units[unitIndex].value === 'meters') {
      widthInFeet *= 3.281;
    } else if (units[unitIndex].value === 'feet') {
      // do nothing, keep in feet
    }
    setWidth(widthInFeet);
    setRenderedWidth(parseFloat(Number(event.target.value).toFixed(3)));
  };

  const handleUnitChange = () => {
    // Cycle through the units array
    const newIndex = (unitIndex + 1) % units.length;
    setUnitIndex(newIndex);

    // Update the renderedWidth
    if (units[newIndex].value === 'meters') {
      setRenderedWidth(parseFloat((width / 3.281).toFixed(3)));
    } else if (units[newIndex].value === 'feet') {
      setRenderedWidth(parseFloat(width.toFixed(3)));
    }
  };

  const openAlignmentEditor = () => {
    const existingAlignment = alignment.find(alignmentElement => alignmentElement.name === currAlignment.name);
    let points = [];
    if (selectedObjects.length === 0) {
      points = genTempCenterLine()
    } else {
      points = getCenterlineData(selectedObjects);
    }

    const newAlignment = new Alignment(
      points,
      currAlignment.name,
      currAlignment.alignmentid,
      currAlignment.items,
      currAlignment.bends,
      currAlignment.conduitOffset
    );

    const alignmentToEdit = AlignmentItemized.fromAlignment(existingAlignment || newAlignment);

    function onSaveAlignment(oldAlignment, newAlignment) {
      const filteredAlignments = alignment.filter(alignmentElement => alignmentElement !== oldAlignment);

      const newAlignmentItemized = new Alignment(
        newAlignment.points,
        newAlignment.name,
        newAlignment.alignmentid,
        newAlignment.items,
        newAlignment.bends,
        newAlignment.conduitOffset

      );

      // Update the state with the new array
      setAlignment([...filteredAlignments, newAlignmentItemized]);
    }

    handleOpenDialog(
      <AlignmentEditor
        alignment={alignmentToEdit}
        handleSaveAlignment={(newAlignment) => onSaveAlignment(alignmentToEdit, newAlignment)}
      />
    );
  };

  return (
    <Box className={className} sx={{ width: '100%' }}>
      <Typography>Alignment Maker</Typography>
      <Autocomplete
        value={alignmentName}
        onChange={(event, newValue) => {
          setAlignmentName(newValue);
        }}
        inputValue={renderedName}
        onInputChange={(event, newInputValue) => {
          setRenderedName(newInputValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '' && !options.some((option) => params.inputValue === option.name)) {
            filtered.push(params.inputValue);
          }
          return filtered;
        }}
        options={alignment.map((align) => align.name)}
        renderInput={(params) => <TextField {...params} label="Name" />}
      />


      <FormControl variant="filled">
        <Box display="flex" alignItems="center">
          <TextField
            id="width-input"
            value={renderedWidth}
            onChange={handleWidthChange}
            type="number"
            sx={{ width: "10em" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="This is the initial width for the alignment">
                    <Typography color="text.secondary">width:</Typography>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={handleUnitChange} sx={{ width: "1em", marginLeft: ".25em" }}>
            <Typography>{units[unitIndex].label}</Typography>
          </IconButton>
          <IconButton onClick={() => setBearingModeIndex((bearingModeIndex + 1) % bearingModes.length)}>
            <Tooltip title="This mode is how equipment will be placed with respect to the alignment line">
              <Typography>{bearingModes[bearingModeIndex].label}</Typography>
            </Tooltip>
          </IconButton>
        </Box>
      </FormControl>

      <Box>
        <TextField
          id="conduit-offset"
          type="number"
          value={conduitOffset}
          onChange={(event) => setConduitOffset(parseFloat(Number(event.target.value).toFixed(3)))}
          sx={{ width: "12em" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="text.secondary">conduit offset:</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        <Button variant="outlined" onClick={openAlignmentEditor}>Edit Alignment</Button>
        <Button
          variant="contained"
          onClick={handleUpdateAlignment}
          className="tutorial-locations-map-joyride-create-alignment"
        >
          Add Selected to Alignment
        </Button>
        <Button variant="outlined" onClick={handleDeleteAlignment}>Delete Alignment</Button>
      </Box>
    </Box >
  );
}