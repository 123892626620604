import { useState, useEffect } from 'react';

import { UseServerData, PostServerData } from "../util/dbfetch.js";

import { AlignmentItemized } from '../models/alignment.ts';
import { AlignmentPerpendicularItemized } from '../models/alignmentPerpendicular.ts';

// 

/**
 * Custom React hook for managing alignment data.
 * #TODO: Impliment LocationArrayClass; alignment has an alignment-item 
 *        array which correspond to each perpendicular
 *
 * @param {string} instanceid - The ID of the instance.
 * @returns {Array} An array containing the alignment array, a setter for the alignment array, a save function for the alignment array, and a loading state for the alignment array.
 */
export default function useAlignment(instanceid) {
  const [alignmentArray, setAlignmentArray] = useState([]);
  const [alignmentArrayLoading, setAlignmentArrayLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);


  function onSaveErrorDefault() {
    console.log('Error saving alignment array.');
  }

  /**
   * Saves the alignment array to the server.
   *
   * @param {Function} onSaveSuccess - A callback function that is called when the save operation is successful.
   */
  function handleSaveAlignmentArray(onSaveSuccess, newInstanceId = instanceid, onSaveError = onSaveErrorDefault) {

    const pyAlignmentArray = alignmentArray.map(alignment => {

      const filteredPoints = alignment.points.map(perpendicular => {
        const pointItems = perpendicular.items ? perpendicular.items.filter(item => Number.isInteger(item.locationid)) : [];
        
        return new AlignmentPerpendicularItemized(
          perpendicular.lat,
          perpendicular.lon,
          perpendicular.width,
          perpendicular.bearingMode,
          perpendicular.alignmentid,
          perpendicular.nodeOSM,
          pointItems,
          perpendicular.order
        )
      });
    
      // we have not been using the below
      const filteredItems = alignment.items ? alignment.items.filter(item => Number.isInteger(item.locationid)) : [];
    
      const thisAlignment = new AlignmentItemized(
        filteredPoints,
        alignment.name,
        alignment.alignmentid,
        filteredItems,
        alignment.bends,
        alignment.conduitOffset
      );
    
      return thisAlignment.toPydanticAPI();
    });

    PostServerData(
      `instance/${newInstanceId}/alignments/new`,
      pyAlignmentArray,
      onSaveSuccess,
      onSaveError
    );
  }

  /**
   * Loads the alignment array from the server data.
   *
   * @param {Array} data - The server data.
   */
  function handleLoadAlignmentArray(data) {


    // check the data, should have unique alignment names
    // if not unique, append alignmentid to the name

    const alignmentNames = data.map(align => align.name);
    const uniqueNames = [...new Set(alignmentNames)];

    if (alignmentNames.length !== uniqueNames.length) {
      data.forEach(align => {
        const nameCount = alignmentNames.filter(name => name === align.name).length;
        if (nameCount > 1) {
          align.name = `${align.name} ${align.alignmentid}`;
        }
      });
    }

    if (data.length > 0) {
      const newAlignments = data.map(alignment => {
        const alignmentPerpendiculars = alignment.points.map(perpendicular =>
          new AlignmentPerpendicularItemized(
            perpendicular.lat,
            perpendicular.lon,
            { left: perpendicular.widthLeft, right: perpendicular.widthRight },
            perpendicular.bearingMode,
            perpendicular.alignmentid,
            perpendicular.nodeOSM,
            perpendicular.items,
          ));

        return new AlignmentItemized(alignmentPerpendiculars, alignment.name, alignment.alignmentid);
      });



      setAlignmentArray(newAlignments);
      setInitialized(true);

    } else {
    }
  }

  /**
   * useEffect hook for fetching the alignment array when initializing.
   */
  useEffect(() => {
    if (!initialized && instanceid !== null) {
      UseServerData(`instance/${instanceid}/alignments`,
        handleLoadAlignmentArray,
        setAlignmentArrayLoading,
        (error) => {
          console.log('Error loading alignment array.');
          console.log(error);
        }
      )
    } else if (instanceid === null) {
      setAlignmentArray([]);
      setInitialized(true);
    }
  }, [initialized, instanceid]);


  return [alignmentArray, setAlignmentArray, handleSaveAlignmentArray, alignmentArrayLoading];
}

