import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  LayersControl,
} from 'react-leaflet';


import 'leaflet-control-geocoder';
import 'leaflet-draw/dist/leaflet.draw.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { CreateBuildingLayer, BuildingLayer } from './layers/createBuilding.jsx';
import { CreateRoadLayer, RoadLayer } from './layers/createRoad.jsx';
import ZoomClickController from './util/zoomClickController.jsx';
import ZoomToInputController from './util/zoomToInputController.jsx';
import ConnectionLayer from './layers/connectionLayer.jsx';
import ConduitLayer from './layers/conduitLayer.jsx';
import AlignmentLayer from './layers/alignmentLayer.jsx';
import EquipmentLayer from './layers/equipmentLayer.jsx';
import SelectedFeatures from './layers/selectedFeatures.jsx';

import MapFeatureSelector from './mapFeatureSelector.jsx';

import LocationArrayClass from '../../models/locationArrayClass.ts';

export default function MyMapContainer({
  mapRef,
  LocationArray,
  reRenderLocationArray,
  selectedObjects,
  setSelectedObjects,
  handleSelected,
  alignments,
  setAlignments,
  handleSaveAlignment,
  editMode,
  onGenerateEquipment,
  handleOpenDialog,
  onMapClickInTab,
  ...props }) {
  // Can Couple Map Zoom bar like this, 
  // anything pertaining to the state will be passed into this


  const setMap = useCallback((mapInstance) => {
    mapRef.current = mapInstance;
  }, [mapRef]);

  const [roadState, setRoadState] = useState([]);
  const roadStateRef = useRef(roadState);
  const [buildingState, setBuildingState] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const initialEquipment = LocationArray.getAllEquipment().find((equipment) => equipment.position && equipment.position.lat && equipment.position.lng);
  const initialCenter = initialEquipment ? [initialEquipment.position.lat, initialEquipment.position.lng] : [37.953158, -122.028504];
  const mapCenter = initialCenter;
  // const [mapCenter, _setMapCenter] = useState(initialCenter);

  const zoom = 15;

  useEffect(() => {
    roadStateRef.current = roadState;
  }, [roadState]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleMapClick = useCallback((event, object = null, object2 = null) => {

    if ((!editMode) && (LocationArray.contains(object))) {
      setSelectedObjects([object]);
    } else {
      onMapClickInTab(event, object, object2);
    }

  }, [LocationArray, editMode, setSelectedObjects, onMapClickInTab]);


  // Reminder, need to assign a className to create the width
  const displayMap = useMemo(() => {
    // Correct placement of filteredLocationArray definition
    const filteredLocationArray = new LocationArrayClass(LocationArray.getFilteredLocations())

    return (
      <Box className="map-display-container" sx={{ width: '100%', minWidth: "600px", height: { sm: '200px', md: "300px", lg: "400px" } }}>
        <MapContainer
          style={{ height: "100%" }}
          center={mapCenter}
          zoom={zoom}
          scrollWheelZoom={false}
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
            OpenStreetMap</a> contributors, Overpass API'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapFeatureSelector
            handleSelected={handleSelected}
            LocationArray={LocationArray}
            roadStateRef={roadStateRef}
            buildingState={buildingState}
          />
          <SelectedFeatures selectedObjects={selectedObjects} LocationArray={LocationArray} />

          {/*  Layer specifics  */}

          <LayersControl className="layer-groups" position="topright">
            <ConduitLayer locationArray={LocationArray} checked name="Conduits" />
            <ConnectionLayer
              isLoaded={isLoaded}
              LocationArray={filteredLocationArray}
              editMode={editMode}
              onMapClick={handleMapClick}
              checked
              name="Connections"
              {...props}
            />
            <EquipmentLayer
              isLoaded={isLoaded}
              LocationArray={filteredLocationArray}
              editMode={editMode}
              onMapClick={handleMapClick}
              setSelectedObjects={setSelectedObjects}
              checked
              name="Equipment"
              {...props}
            />
            <RoadLayer
              isLoaded={isLoaded}
              roadState={roadState}
              onMapClick={handleMapClick}
              checked
              name="Roads"
              {...props}
            />
            <AlignmentLayer
              alignments={alignments}
              setAlignments={setAlignments}
              handleOpenDialog={handleOpenDialog}
              editMode={editMode}
              checked
              name="Alignments"
              {...props}
            />
            <BuildingLayer
              buildingState={buildingState}
              checked
              name="Buildings"
              {...props}
            />
          </LayersControl>
        </MapContainer>
      </Box>
    );
  }, [
    setMap, alignments, setAlignments, handleOpenDialog, handleSelected, selectedObjects, setSelectedObjects,
    LocationArray, editMode, roadState, buildingState, handleMapClick, isLoaded, props, mapCenter
  ]);

  return (
    <Box className="map-container" sx={{ width: '100%', height: '100%' }}>
      {/* Buttons at Top */}
      {mapRef.current ? <CreateBuildingLayer className="create-building-layer" parentMap={mapRef.current} isLoaded={isLoaded} setBuildingState={setBuildingState} /> : null}
      {mapRef.current ? <CreateRoadLayer className="create-road-layer" parentMap={mapRef.current} isLoaded={isLoaded} setRoadState={setRoadState} /> : null}
      <Button onClick={(ev) => handleSaveAlignment(ev)}>Save Alignment</Button>

      {/* Search Bar */}
      {mapRef.current ? <ZoomToInputController className="zoom-to-input-controller" parentMap={mapRef.current} isLoaded={isLoaded} /> : null}
      {mapRef.current ? <ZoomClickController className="zoom-click-controller" parentMap={mapRef.current} isLoaded={isLoaded} /> : null}

      {displayMap}
    </Box>
  );
}


export function MyMapContainerReadOnly({
  LocationArray,
  selectedObjects,
  ...props }) {
  // Can Couple Map Zoom bar like this, 
  // anything pertaining to the state will be passed into this
  const [map, setMap] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const editMode = false

  const initialEquipment = LocationArray.getAllEquipment(true).find((equipment) => equipment?.position && equipment?.position.lat && equipment?.position.lng);
  const initialCenter = initialEquipment ? [initialEquipment.position.lat, initialEquipment.position.lng] : [37.953158, -122.028504];
  const mapCenter = initialCenter;
  // const [mapCenter, _setMapCenter] = useState(initialCenter);

  const zoom = 15;

  useEffect(() => {
    setIsLoaded(true);
  }, []);


  const handleMapClick = useCallback((event) => {
    if (!editMode) {

    }
  }, [editMode]);

  const handleCreateCable = useCallback((equipment1, equipment2) => {
    // "Read Only Do Nothing"
  }, []);


  // Reminder, need to assign a className to create the width
  const displayMap = useMemo(() => {
    if (LocationArray.length === 0) {
      return null;
    }

    return (
      <Box className="map-display-container" sx={{ width: '100%', height: { sm: '200px', md: "300px", lg: "400px" } }}>
        <MapContainer
          style={{ height: "100%" }}
          center={mapCenter}
          zoom={zoom}
          scrollWheelZoom={false}
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
            OpenStreetMap</a> contributors, Overpass API'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LayersControl className="layer-groups" position="topright">
            <ConnectionLayer
              isLoaded={isLoaded}
              LocationArray={LocationArray}
              editMode={editMode}
              {...props}
            />
            <EquipmentLayer
              isLoaded={isLoaded}
              LocationArray={LocationArray}
              editMode={editMode}
              onMapClick={handleMapClick}
              onCreateCable={handleCreateCable}
              {...props}
            />
          </LayersControl>
        </MapContainer>
      </Box>
    );
  }, [LocationArray, editMode, handleMapClick, isLoaded, props, mapCenter, handleCreateCable]);

  return (
    <Box className="map-container" sx={{ width: '100%', height: '100%' }}>
      {map ? <ZoomToInputController className="zoom-to-input-controller" parentMap={map} isLoaded={isLoaded} /> : null}
      {map ? <ZoomClickController className="zoom-click-controller" parentMap={map} isLoaded={isLoaded} /> : null}

      {displayMap}
    </Box>
  );
}

