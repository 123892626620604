// Build a component to load some organization and display this data
// should load the user, and get their responsibilities

import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { UseServerData, PostServerData } from "../../util/dbfetch";


import useUserDataContext from '../../util/providers/userDataProvider.jsx';

import JoyRideStarter from "../../components/joyRideStarter";
import useSnackbarContext from "../../util/providers/snackbarProvider";

import TransferResponsibilityOrganization from "../../components/transfers/transferResponsibilityOrganization";
import OrganizationManagerView from "./organizationManagerView";


export default function OrganizationDetailer({ onOpenDialog, onCloseDialog }) {
  const navigate = useNavigate();
  const { orgID } = useParams();

  const [organization, setOrganization] = useState(null)
  const [users, setUsers] = useState([])
  const [isManagerVerified, setIsManagerVerified] = useState(false)

  const [activeProjects, setActiveProjects] = useState(0);

  const [isEditingOrg, setIsEditingOrg] = useState(false);
  const [newOrgName, setNewOrgName] = useState(organization?.organization_name);
  const [newOrgDesc, setNewOrgDesc] = useState(organization?.organization_desc);

  const { refreshData } = useUserDataContext();

  const { openSnackbar } = useSnackbarContext();

  const steps = [
    {
      target: '.register-join-buttons',
      content: (
        <>
          <Typography>Use these buttons to create, join, or delete Organizations.</Typography>
        </>
      ),
      placement: "right",
      disableBeacon: true,
    },
    {
      target: '.organization-info',
      content: (
        <>
          <Typography>Create an Organization to share and collaborate on projects.</Typography>
          <Typography>Each Organization is single manager.</Typography>
        </>
      ),
      disableBeacon: true,
    },
    {
      target: '.manager-view',
      content: (
        <>
          <Typography>
            Managers are responsible for managing user accounts, sending invites, and assigning roles.
          </Typography>
          <Typography>
            Note: Members can start new projects, but premium features require approval.
          </Typography>
        </>
      ),
    },
    {
      target: '.project-view',
      content: (
        <>
          <Typography>
            View all projects here. Click on a project for details.
          </Typography>
        </>
      ),
    },
    {
      target: '.members-view',
      content: (
        <>
          <Typography>
            You can see all members of your organization.
          </Typography>
        </>
      ),
    },
  ];


  // Python should read if the user is a manager, and if so, load the organization manager data
  // want to load with the projects, with organization_view

  useEffect(() => {
    UseServerData(`organizations/${orgID}`, setOrganization, () => null, () => { });

  }, [orgID])


  // Get number of active projects within the last month. 
  useEffect(() => {
    const projects = organization?.projects
    const activeProjects = projects?.filter(project => {
      const lastMonth = new Date()
      lastMonth.setMonth(lastMonth.getMonth() - 1)
      const dateUpdate = new Date(project?.instanceCurrent?.date_update)
      return dateUpdate instanceof Date && !isNaN(dateUpdate) && dateUpdate > lastMonth
    }).length

    setActiveProjects(activeProjects)

    setUsers(organization?.members)
  }, [organization])


  if (organization === null) {
    return (
      <Box>
        <Typography>Organization Not Found</Typography>
      </Box>
    )
  }

  const handleEditClick = () => {
    setNewOrgName(organization?.organization_name);
    setNewOrgDesc(organization?.organization_desc);
    setIsEditingOrg(!isEditingOrg);
  };

  const handleSaveClick = () => {
    const updatedOrganization = { ...organization, organization_name: newOrgName, organization_desc: newOrgDesc };
    PostServerData(`organizations/${organization.organizationid}/update`, updatedOrganization, () => {
      setOrganization(updatedOrganization);
      setIsEditingOrg(false);
      refreshData();
      openSnackbar('Organization Updated');
    }, (resp) => openSnackbar(resp));

  };

  const handleCancelClick = () => {
    setNewOrgName(organization?.organization_name);
    setNewOrgDesc(organization?.organization_desc);
    setIsEditingOrg(false);
  };

  return (
    <>
      <JoyRideStarter steps={steps}
        sx={{
          height: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
        }} />

      {/* Organization Info */}
      <Paper className="organization-info" sx={{ mb: 4, border: 1 }}>
        {isEditingOrg ? (
          <>
            <TextField
              label="Organization Name"
              className="organization-name"
              variant="outlined"
              defaultValue={newOrgName}
              onChange={(e) => setNewOrgName(e.target.value)}
              sx={{ my: 1, width: '100%' }}
            />
            <TextField
              label="Organization Description"
              multiline
              className="organization-desc"
              variant="outlined"
              defaultValue={newOrgDesc}
              onChange={(e) => setNewOrgDesc(e.target.value)}
              sx={{ my: 1, width: '100%' }}
            />
            <IconButton onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography className="organization-name" variant="h1">{organization?.organization_name}</Typography>
              {isManagerVerified ? <IconButton onClick={handleEditClick}>
                <EditIcon />
              </IconButton> : null}
            </Box>
            <Box className="organization-desc" sx={{ m: 2 }}>
              <Typography>{organization?.organization_desc}</Typography>
            </Box>
          </>
        )}
      </Paper>

      {/* Open RFPs */}
      <Paper className="tutorial-org-rfp open-rfps" sx={{ mb: 4, border: 1 }}>
        {organization?.rfp ? (
          <>
            <Typography className="open-rfps-title" variant="h3">Open RFPs: {organization?.rfp?.length}</Typography>
            {organization?.rfp.map((rfp) => (
              <Box key={rfp.instanceid} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }}>
                <Typography>Deadline: {new Date(rfp.deadline).toLocaleDateString()}</Typography>
                <Button variant={"outlined"} key={rfp.instanceid} onClick={() => navigate(`/organizations/${orgID}/request-for-proposal/${rfp.instanceid}`)}>
                  Go To
                </Button>
              </Box>
            ))}
          </>
        ) :
          <Typography className="open-rfps-title" variant="h3">No Open RFPs</Typography>
        }
      </Paper>


      {/* Manager View */}
      <OrganizationManagerView
        organization={organization}
        users={users}
        activeProjects={activeProjects}
        onOpenDialog={onOpenDialog}
        onCloseDialog={onCloseDialog}
        isManagerVerified={isManagerVerified}
        setIsManagerVerified={setIsManagerVerified}
      />

      {/* Project View */}
      <Paper className="project-view" sx={{ mb: 4, border: 1 }}>
        <Typography className="active-projects-title" variant="h1">Active Projects:</Typography>
        {organization?.projects ? (
          organization?.projects?.map((project) => (
            <Accordion key={project.projectid}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button variant="outlined" onClick={() => navigate(`/project/${project.projectid}`)}>Go to:</Button>
                  <Typography>{project.proj_name}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Description: {project.proj_desc}</Typography>
                <Button sx={{ ml: 4 }} variant="contained" onClick={() =>
                  onOpenDialog(
                    <TransferResponsibilityOrganization organization={organization} />
                  )}>Reassign/Transfer</Button>

                {project.instanceCurrent && (
                  <Card sx={{ p: 2 }} elevation={3}>
                    <Typography>
                      Current Phase: {project.instanceCurrent.project_phase}
                      {project.instanceCurrent?.date_update ? `   Updated: ${project.instanceCurrent.date_update}` : ''}
                    </Typography>
                    {project.instanceCurrent?.instance_desc && (<Typography>Current Instance: {project.instanceCurrent.instance_desc}</Typography>)}
                  </Card>

                )}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box>
            <Typography className="no-projects-message" variant="h1">No Organization Projects</Typography>
          </Box>
        )
        }
      </Paper>

      {/* Members View */}
      <Paper className="members-view" sx={{ mb: 4, border: 1 }}>
        <Typography className="members-title" variant="h1">Members</Typography>
        {users ? (
          <List>
            {users.map((member) => (
              <ListItem key={member.userid}>
                <ListItemText primary={member.username} />
              </ListItem>
            ))}
          </List>
        ) : (
          null
        )}
      </Paper>
    </>
  );
}
