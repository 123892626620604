
import { useEffect, useState } from 'react';

import { UseServerData, PostServerData } from "../util/dbfetch.js";
import useSnackbarContext from '../util/providers/snackbarProvider';

import { PriceProfile, PriceProfileList } from '../models/priceProfile.ts';

export const useInstancePriceProfile = (instanceid) => {
  const [priceProfileList, setPriceProfileList] = useState([PriceProfileList.default()]);
  const [loadingPPList, setLoading] = useState(true);

  const { openSnackbar } = useSnackbarContext();


  useEffect(() => {
    function handleSetInitialPriceProfileGroup(newppList) {
      if (newppList.length === 0) {
        return;
      }
      // How does this handle the existing defaults? We should overwrite them?
      const priceProfileGroups = newppList.map(
        ppGroup => new PriceProfileList(
          ppGroup.priceprofilegroupid,
          ppGroup.name,
          ppGroup.price_profiles.map(pp => new PriceProfile(pp))
        )
      );

      setPriceProfileList(prevPriceProfileList => {
        const updatedList = [...prevPriceProfileList];

        priceProfileGroups.forEach(newGroup => {
          const existsInPrevList = prevPriceProfileList.some(
            prevGroup => prevGroup.priceprofilegroupid === newGroup.priceprofilegroupid
          );

          if (!existsInPrevList) {
            updatedList.push(newGroup);
          }
        });

        return updatedList;
      });
    }

    if (loadingPPList) {
      UseServerData(
        `instance/${instanceid}/price-profile-group`,
        handleSetInitialPriceProfileGroup,
        setLoading,
        (err) => {
          openSnackbar(`error; ${err}`)
        })
    }
  }, [instanceid, loadingPPList, openSnackbar]);

  // Hook to save the priceProfile to the server
  function savePriceProfileToInstance(selectedPriceProfileList) {
    // if there is a loaded pp group, add that to the query parameters
    let params = {}
    if (selectedPriceProfileList.priceprofilegroupid) {
      params = { "priceprofilegroupid": selectedPriceProfileList.priceprofilegroupid }
    }
    PostServerData(
      `instance/${instanceid}/price-profile`,
      selectedPriceProfileList,
      () => { openSnackbar("Price Profile Saved.") },
      (d) => { openSnackbar(`error: ${String(d)}`) },
      params // is priceProfilegroupid needed?
    )
  }


  return {
    priceProfileList,
    loadingPPList,
    savePriceProfileToInstance,
  };
};


export const useOrgPriceProfile = (organizationid, instanceid) => {
  const [priceProfileList, setPriceProfileList] = useState([PriceProfileList.default()]);
  const [loading, setLoading] = useState(true);


  const { openSnackbar } = useSnackbarContext();

  useEffect(() => {
    function handleSetInitialPriceProfileGroup(newppList) {
      if (newppList.length === 0) {
        return;
      }

      const priceProfileGroups = newppList.map(
        ppGroup => new PriceProfileList(
          ppGroup.priceprofilegroupid,
          ppGroup.name,
          ppGroup.price_profiles.map(pp => new PriceProfile(pp))
        )
      );

      setPriceProfileList(prevPriceProfileList => {
        const updatedList = [...prevPriceProfileList];

        priceProfileGroups.forEach(newGroup => {
          const existsInPrevList = prevPriceProfileList.some(
            prevGroup => prevGroup.priceprofilegroupid === newGroup.priceprofilegroupid
          );

          if (!existsInPrevList) {
            updatedList.push(newGroup);
          }
        });

        return updatedList;
      });
    }

    if (organizationid & loading) {
      UseServerData(
        `organization/${organizationid}/price-profile-group`,
        handleSetInitialPriceProfileGroup,
        setLoading,
        () => { },
        { instanceid: instanceid })
    }
  }, [priceProfileList, organizationid, instanceid, loading]);

  // Hook to save the priceProfile to the server
  function savePriceProfileToOrg(selectedPriceProfile, saveToOrg, priceprofilegroupid = null) {
    // if there is a loaded pp group, add that to the query parameters
    const params = {
      saveToOrg: saveToOrg,
      priceprofilegroupid: priceprofilegroupid,
    }
    PostServerData(
      `organization/${organizationid}/request-for-proposal/${instanceid}`, selectedPriceProfile,
      (d) => { openSnackbar("Price Profile Saved.") },
      (d) => { openSnackbar(`error: ${d}`) },
      params // is priceProfilegroupid needed?
    )
  }

  return {
    priceProfileList,
    loading,
    savePriceProfileToOrg,
  };
};
