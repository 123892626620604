import React, { useState } from 'react';

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import { withGenericConfirmWrapper } from '../util/withGenericConfirmWrapper';


export default function ConfirmDialogContent({ title, message, confirmText = 'OK', cancelText = 'Cancel', onConfirm, onCancel }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    onConfirm();
  };

  const handleClose = () => {
    onCancel(false);
  }

  // const handleError = () => {
  // }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof message === 'string' ? message : message}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelText}</Button>
        <Button disabled={loading} onClick={handleSubmit}>
          {loading ? <CircularProgress size={24} /> : confirmText}</Button>
      </DialogActions>
    </>
  );
}


export const ConfirmDialog = withGenericConfirmWrapper(ConfirmDialogContent);


export function SampleDialog() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  function confirmDelete() {
    handleOpenDialog(
      <ConfirmDialog
        message="Are you sure you want to clear all of these conduits?"
        onConfirm={() => {
          // Other Functions
          setDialogOpen(false)
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  return (
    <>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <Button onClick={confirmDelete}>Open Dialog</Button>
    </>
  )
}