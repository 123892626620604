import React, { createContext, useEffect, useState  } from 'react';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import Cookies from 'js-cookie';

import './App.css';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';


import { ProjectRoute } from './pages/project/projectRoute';
import OrganizationRoute  from './pages/organization/OrganizationRoute';
import ResetPasswordForm from './pages/user/resetPasswordForm';
import UserRoute from './pages/user/userRoute';
import PageNotFound from './pages/pageNotFound';
import SampleTheme from './pages/themeDisplay';
import RouteHeader from './pages/adornments/routeHeader';
import RouteHeaderVistor from './pages/adornments/routeHeaderVisitor';
import ReturningUser from './pages/returningUser';
import NewUserHomePage from './pages/newUserHomePage';

import AboutPage from './pages/aboutPage';
import TutorialLoader from './components/tutorial/tutorialLoader';

import ThemeManager from "./theme/themeManager";
import { useTutorialState } from './hooks/useTutorialState';

import { UserDataProvider } from './util/providers/userDataProvider';
import { SnackbarProvider } from './util/providers/snackbarProvider';

export const TutorialContext = createContext();

export default function App() {
  const [jwt, setJwt] = useState(null);

  
  const [tutorialState, currentTutorial, 
    handleStartTutorial, handleProgress, 
    handleStopTutorial, updateTutorialStateParameters
  ] = useTutorialState();


  useEffect(() => {
    const jwt = Cookies.get('jwtToken');
    setJwt(jwt);
    if (jwt) {
    }
  }, [jwt]);


  // Check out https://zenoo.github.io/mui-theme-creator/
  return (
    <ThemeManager>
      <CssBaseline />
      <BrowserRouter>
        <Container >
          {/*  Routes can change the jwt to force a refresh */}
            <SnackbarProvider>
              <TutorialContext.Provider value={{ tutorialState, handleStartTutorial, handleProgress }}>
                {jwt ? (
                  <UserDataProvider jwt ={jwt}>
                    {/* Routes available when jwt is present */}
                    <RouteHeader onLogout={()=>setJwt(1)}/>
                    {!tutorialState.isEmpty() && <TutorialLoader currentTutorial={currentTutorial} handleStopTutorial = {handleStopTutorial} updateTutorialStateParameters ={updateTutorialStateParameters}/>}
                    <Routes>
                      <Route path="" element={<ReturningUser />} />
                      <Route path="about" element={<AboutPage />} />
                      <Route path="theme" element={<SampleTheme />} />
                      <Route path="project/*" element={<ProjectRoute />} />
                      <Route path="organizations/*" element={<OrganizationRoute />} />
                      <Route path="user/*" element={<UserRoute />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </UserDataProvider>
                ) : (
                  // Routes available when jwt is not present
                  <>
                    <RouteHeaderVistor onLogin={()=>setJwt(1)}/>
                    <Routes>
                      <Route path="" element={<NewUserHomePage onLogin={()=>setJwt(1)} onRegister ={handleStartTutorial}/>} />
                      <Route path="about" element={<AboutPage />} />
                      <Route path="theme" element={<SampleTheme />} />
                      <Route path="reset-password" element={<ResetPasswordForm />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </>
                )}

              </TutorialContext.Provider>  
            </SnackbarProvider>
          <Box sx={{ height: 140 }}/>
        </Container>
      </BrowserRouter>
    </ThemeManager>
  );
}