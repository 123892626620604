import React, { useState, useEffect, useContext, useCallback } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { lineString, pointToLineDistance, distance, nearestPointOnLine } from '@turf/turf';

import { LocationContext } from '../../util/providers/locationContext.jsx';
import useSnackbarContext from '../../util/providers/snackbarProvider.jsx';

import { AlignmentItemized } from "../../models/alignment.ts"
import { AlignmentPerpendicularItemized } from "../../models/alignmentPerpendicular.ts"

import { sortNumAlpha } from '../../util/sortNumAlpha.js';

import JoyRideStarter from '../joyRideStarter.jsx';
import AlignmentPointTable from './alignmentPointTable.jsx';
import EditedAlignmentMap from './editedAlignmentMap.jsx';

const bearingModes = [
  { value: 'bisect', label: 'Bisect' },
  { value: 'headAngle', label: 'Head Angle' },
  { value: 'tailAngle', label: 'Tail Angle' },
];

/**
 * A component to edit an alignment point by point.  
 * I should be able to change each AlignmentPerpendicularItemized width, bearingMode, equipment and bends here.
 * When finished I should replace the old alignment with the new one with a save changes button
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {AlignmentItemized} props.alignment - An Alignment object to be rendered on the map.
 * @param {Function} props.handleSaveAlignment - A function to handle saving the alignment globally.
 * 
 */
export default function AlignmentEditor({ alignment, handleSaveAlignment }) {
  const [editedAlignment, setEditedAlignment] = useState(alignment);
  const [alignmentName, setAlignmentName] = useState(editedAlignment.name);

  const [pendingChanges, setPendingChanges] = useState(false);

  const [selectedPoint, setSelectedPoint] = useState(editedAlignment.points[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [widthLeft, setWidthLeft] = useState(0);
  const [widthRight, setWidthRight] = useState(0);
  const [bearingMode, setBearingMode] = useState("");
  const [locationid, setLocationid] = useState("");
  const [offset, setOffset] = useState(0);
  const [angle, setAngle] = useState(0);

  const [renderKey, setRenderKey] = useState(0);
  const [delayedUpdate, setDelayedUpdate] = useState(false);

  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);
  const { openSnackbar } = useSnackbarContext();

  const [maxWidthLeft, setMaxWidthLeft] = useState(0);
  const [maxWidthRight, setMaxWidthRight] = useState(0);

  const steps = [
    {
      target: '.alignment-point-map',
      content: `Displays all points in the alignment with their perpendiculars.
      Enter edit mode with the button in the upper right corner.`,
      placement: "right",
      disableBeacon: true,
    },
    {
      target: '.alignment-point-one-dimensional',
      content: `One dimensional views of each alignment point.  
      All relative widths are shown as well as equipment at the point.`,
      placement: "left",
      disableBeacon: true,
    },
    {
      target: '.alignment-point-fields',
      content: `Fields for editing each point. Bisect / Head / Tail Angle are the bearing modes
       for how equipment is aligned at each point.  
       The Right of Way are defined from these widths left and right.`,
      placement: "right",
      disableBeacon: true,
    },
    {
      target: '.alignment-point-equipment-fields',
      content: `For each selected point you can add equipment.  
      These relative positions are used for any calculations.`,
      placement: "left",
      disableBeacon: true,
    },
  ]

  useEffect(() => {
    let maxLeft = maxWidthLeft;
    let maxRight = maxWidthRight;

    editedAlignment.points.forEach(point => {
      maxLeft = Math.max(maxLeft, point.width.left);
      maxRight = Math.max(maxRight, point.width.right);

      point.items.forEach(item => {
        if (item.offset < 0) {
          // If the offset is negative, add it to the left width
          maxLeft = Math.max(maxLeft, -item.offset);
        } else {
          // If the offset is positive, add it to the right width
          maxRight = Math.max(maxRight, item.offset);
        }
      });
    });

    setMaxWidthLeft(maxLeft);
    setMaxWidthRight(maxRight);
  }, [editedAlignment, maxWidthLeft, maxWidthRight]);

  useEffect(() => {
    const newPoint = editedAlignment.points[selectedIndex];
    setSelectedPoint(newPoint);

    if (newPoint) {
      setWidthLeft(newPoint.width.left);
      setWidthRight(newPoint.width.right);
      setBearingMode(newPoint.bearingMode);

      // reset the locationid to the first item in newPoint.items
      if (newPoint.items.length) {
        setLocationid(newPoint.items[0].locationid);
      } else {
        setLocationid("");
      }
    }
  }, [selectedIndex, editedAlignment.points]);


  useEffect(() => {
    if (locationid) {
      // find location item in selectedpoint.items.locationid
      const selectedLocation = selectedPoint.items.find(item => item.locationid === locationid);

      if (selectedLocation) {
        setOffset(selectedLocation.offset);
        setAngle(selectedLocation.angle);
      } else {
        setOffset(0);
        setAngle(0);
      }
    }

  }, [locationid, selectedPoint]);


  const updateSelectedIndex = useCallback((eAlignment, selectedIndex, widthLeft, widthRight, bearingMode) => {
    if (widthLeft > maxWidthLeft) {
      setMaxWidthLeft(widthLeft);
    }

    if (widthRight > maxWidthRight) {
      setMaxWidthRight(widthRight);
    }

    // updates the selected point with the new width and bearingMode
    const newPoints = eAlignment.points.map((point, index) => {
      if (index === selectedIndex) {
        return new AlignmentPerpendicularItemized(
          point.lat,
          point.lon,
          { "left": widthLeft, "right": widthRight },
          bearingMode,
          point.alignmentpointid,
          point.nodeOSM,
          point.items,
        );
      } else {
        return point;
      }
    });

    setEditedAlignment(new AlignmentItemized(
      newPoints,
      eAlignment.name,
      eAlignment.alignmentid,
      eAlignment.items,
      eAlignment.bends,
      eAlignment.conduitOffset));

    setRenderKey(prevRenderKey => prevRenderKey + 1);
  }, [maxWidthLeft, maxWidthRight]);

  useEffect(() => {
    if (delayedUpdate) {
      const timeoutId = setTimeout(() => {
        updateSelectedIndex(editedAlignment, selectedIndex, widthLeft, widthRight, bearingMode);
        setDelayedUpdate(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [delayedUpdate, updateSelectedIndex, editedAlignment, selectedIndex, widthLeft, widthRight, bearingMode]);

  // Methods

  const handleAddEquipment = () => {
    editedAlignment.updatePointItem({ locationid, offset, angle }, selectedIndex);

    const newPoints = editedAlignment.points

    setRenderKey(prevRenderKey => prevRenderKey + 1);
    setEditedAlignment(
      new AlignmentItemized(
        newPoints,
        editedAlignment.name,
        editedAlignment.alignmentid,
        editedAlignment.items,
        editedAlignment.bends,
        editedAlignment.conduitOffset
      ));

    setPendingChanges(true);
  };

  const handleInsertAlignmentPoint = (newPoint, equipPoint) => {

    // newPoint : <Point >

    const error = 0.000001;

    // find insert point in alignment
    let insertIndex = -1;
    let prevPoint = editedAlignment.points[0];
    for (let i = 0; i < editedAlignment.points.length - 1; i++) {
      const currentPoint = editedAlignment.points[i];
      const nextPoint = editedAlignment.points[i + 1];

      const thisLine = lineString([[currentPoint.lon, currentPoint.lat], [nextPoint.lon, nextPoint.lat]]);

      const currDist = pointToLineDistance(newPoint, thisLine);

      if (currDist < error) {
        insertIndex = i+1;
        prevPoint = currentPoint;
        break;
      }
    }

    if (insertIndex === -1) {
      console.error('New Point not on alignment line.');
      return;
    }


    // determine if the equipPoint is to the left or the right of the newPoints
    const isLeftOrRight = (A, B, P) => {
      const crossProduct = (B.lon - A.lon) * (P.lat - A.lat) - (B.lat - A.lat) * (P.lon - A.lon);
      return crossProduct > 0 ? 'left' : 'right';
    };

    const footOffset = distance(newPoint, equipPoint, { units: 'feet' });
    const direction = isLeftOrRight(prevPoint, {
        lat: newPoint.geometry.coordinates[1], lon: newPoint.geometry.coordinates[0]
        }, {
          lat: equipPoint.geometry.coordinates[1], lon: equipPoint.geometry.coordinates[0]
          }
    );    

    const newAP = new AlignmentPerpendicularItemized(
      newPoint.geometry.coordinates[1],
      newPoint.geometry.coordinates[0],
      prevPoint.width,
      prevPoint.bearingMode,
    );


    if (direction === 'left') {
      newAP.items.push({ locationid, offset: -1 * footOffset, angle: 0 });
    } else {
      newAP.items.push({ locationid,offset: footOffset, angle: 0 });
    }

    // insert newPoint into alignment
    const newPoints = [...editedAlignment.points];
    newPoints.splice(insertIndex, 0, newAP);

    return new AlignmentItemized(
        newPoints,
        editedAlignment.name,
        editedAlignment.alignmentid,
        editedAlignment.items,
        editedAlignment.bends,
        editedAlignment.conduitOffset
      );
  }

  const handleAutoAlignEquipment = () => {
    // from the current equipment location

    const equipPoint = LocationArray.find(location => location.locationid === locationid)?.toGeoJSON();
    
    // get shortest path/point on alignment linestring

    // get shortest path/point on alignment linestring
    const alignmentCenterLine = editedAlignment.createCenterLine();
    const nearPoint = nearestPointOnLine(alignmentCenterLine, equipPoint);

    if (!nearPoint) {
      openSnackbar('Unable to find a nearest Point');
      return;
    }
    // Check if nearPoint is a vertex in the updated alignment
    const isVertex = editedAlignment.points.some(point => 
      point.lat === nearPoint.geometry.coordinates[1] && point.lon === nearPoint.geometry.coordinates[0]
    );

    if (isVertex) {
      openSnackbar('Error generating new point, consider connecting manually.');
      return 
    }

    const newAlignment = handleInsertAlignmentPoint(nearPoint, equipPoint);

    if (!newAlignment) {
      openSnackbar('Error crearing alignment');
      return;
    }

    setEditedAlignment(newAlignment);
    setRenderKey(prevRenderKey => prevRenderKey + 1);
    setPendingChanges(true);
  };

  const handleClearItem = () => {
    editedAlignment.removePointItem(selectedIndex, locationid);
    setRenderKey(prevRenderKey => prevRenderKey + 1);
    setPendingChanges(true);
  }
    

  const handleSyncEquipLatLong = () => {
    // Use the getItemPosition method from editedAlignment to get the item's position
    const itemPositionFeature = editedAlignment.getItemPosition(locationid);

    // Extract latitude and longitude from the itemPositionFeature
    const [lng, lat] = itemPositionFeature.geometry.coordinates;

    // Update the LocationArray Lat/Long with the new values
    // Assuming there's a function or a way to update LocationArray here

    LocationArray.update(locationid, { position: { lat, lng } });
    // updateLocationArray(lat, long);
    reRenderLocationArray();
  }

  const handleSyncAllLatLong = () => {
    editedAlignment.points.forEach(point => {
      point.items.forEach(item => {
        const itemPositionFeature = editedAlignment.getItemPosition(item.locationid);
        const [lng, lat] = itemPositionFeature.geometry.coordinates;

        LocationArray.update(item.locationid, { position: { lat, lng } });
      });
    });
  }


  const handleSaveChanges = () => {
    const updatedAlignment = { ...editedAlignment, name: alignmentName };

    setPendingChanges(false);
    handleSaveAlignment(updatedAlignment);
    setRenderKey(prevRenderKey => prevRenderKey + 1);
  };

  const handleItemClick = (pointIndex, locationid) => {
    setLocationid(locationid);
    setSelectedIndex(pointIndex);
  };

  const handleMapFeatureClick = (index, feature) => {
    setSelectedIndex(index);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          label="Alignment Name"
          value={alignmentName}
          onChange={(e) => {
            setAlignmentName(e.target.value)
            setPendingChanges(true);
          }}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="outlined" onClick={handleSyncAllLatLong} >Sync All Locations</Button>
        <Button variant="outlined" onClick={handleSaveChanges} >Save Changes</Button>
        {pendingChanges && <Typography variant="h6" sx={{ px: 2 }}>Save to commit pending changes</Typography>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '60%', maxHeight: "400px" }}>
        <Box className="alignment-point-map" sx={{ height: '400px', width: '60%' }}>
          <EditedAlignmentMap
            renderKey={renderKey}
            rerenderTrigger={() => setRenderKey(prevRenderKey => prevRenderKey + 1)}
            originalAlignment={editedAlignment}
            origPointFeatures={editedAlignment.getPointFeatures()}
            setOriginalAlignment={setEditedAlignment}
            locationArray={LocationArray}
            currentLocationid={locationid}
            handleMapFeatureClick={handleMapFeatureClick}
            selectedIndex={selectedIndex}
            setPendingChanges={setPendingChanges}
          />
        </Box>
        <Box className="alignment-point-one-dimensional" sx={{ width: '40%', overflow: 'auto', overflowX: "auto" }}>
          <AlignmentPointTable
            renderKey={renderKey}
            selectedIndex={selectedIndex}
            points={editedAlignment.points}
            locationArray={LocationArray}
            onItemClick={handleItemClick}
            maxWidthLeft={maxWidthLeft}
            maxWidthRight={maxWidthRight}

          />
        </Box>

        <JoyRideStarter
          steps={steps}
          width={0}
          anchorVert={-8}
          disableScrolling={true}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', height: '40%', py: 2 }}>
        <Paper className="alignment-point-fields" sx={{ width: '40%', px: 1, overflow: 'hidden' }}>
          <PointFormEditor
            editedAlignment={editedAlignment}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            bearingMode={bearingMode}
            setBearingMode={setBearingMode}
            setPendingChanges={setPendingChanges}
            widthLeft={widthLeft}
            setWidthLeft={setWidthLeft}
            widthRight={widthRight}
            setWidthRight={setWidthRight}
            setDelayedUpdate={setDelayedUpdate}
          />
        </Paper>

        <Paper className="alignment-point-equipment-fields" sx={{ width: '59%', px: 1, overflow: 'hidden' }}>
          <EquipmentFormEditor
            locationArray={LocationArray}
            locationid={locationid}
            setLocationid={setLocationid}
            offset={offset}
            setOffset={setOffset}
            angle={angle}
            setAngle={setAngle}
            handleAddEquipment={handleAddEquipment}
            handleSyncEquipLatLong={handleSyncEquipLatLong}
            handleClearItem={handleClearItem}
            handleAutoAlignEquipment={handleAutoAlignEquipment}
          />
        </Paper>
      </Box>
    </Box>
  );
}


function PointFormEditor({
  editedAlignment,
  selectedIndex,
  setSelectedIndex,
  bearingMode,
  setBearingMode,
  setPendingChanges,
  widthLeft,
  setWidthLeft,
  widthRight,
  setWidthRight,
  setDelayedUpdate,
}) {


  const handleBearingModeChange = (event) => {
    setBearingMode(event.target.value);
    setDelayedUpdate(true);
  };

  return (
    <Box sx={{ py: 1, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6">Alignment Point</Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Select
          value={selectedIndex}
          onChange={(event) => {
            setSelectedIndex(event.target.value);
          }}
          disabled={!editedAlignment.points.length}
        >
          {editedAlignment.points.map((point, index) => (
            <MenuItem key={index} value={index}>
              {point.items.length === 0 ? index : `${index} - ${point.items.length} Locs`}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="bearingMode"
          value={bearingMode}
          onChange={(event) => {
            handleBearingModeChange(event);
            setPendingChanges(true);
          }}
        >
          {bearingModes.map((mode) => (
            <MenuItem key={mode.value} value={mode.value}>
              {mode.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>

        <TextField
          type="number"
          name="widthLeft"
          label="Width Left"
          value={widthLeft}
          onChange={(event) => {
            setWidthLeft(event.target.value);
            setDelayedUpdate(true);
            setPendingChanges(true);
          }}
          sx={{ mt: 1, width: "50%", minWidth: "100px" }}
        />
        <TextField
          type="number"
          name="widthRight"
          label="Width Right"
          value={widthRight}
          onChange={(event) => {
            setWidthRight(event.target.value);
            setDelayedUpdate(true);
            setPendingChanges(true);
          }}
          sx={{ mt: 1, width: "50%", minWidth: "100px" }}
        />
      </Box>
    </Box>
  );
}

function EquipmentFormEditor({
  locationArray,
  locationid,
  setLocationid,
  offset,
  setOffset,
  angle,
  setAngle,
  handleAddEquipment,
  handleSyncEquipLatLong,
  handleClearItem,
  handleAutoAlignEquipment,
}) {


  const onAddEquipment = () => {
    if (locationid) {
      handleAddEquipment();
    }
  };

  const onAutoAlignEquipment = () => {
    if (locationid) {
      handleAutoAlignEquipment();
    }
  };


  const onSyncEquipment = () => {
    if (locationid) {
      handleSyncEquipLatLong();
    }
  };

  const onClearItem = () => {
    if (locationid) {
      handleClearItem();
    }
  }

  return (
    <Box sx={{ py: 1, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6">Equipment:</Typography>
      <Box  sx={{ display: "flex", flexDirection: "row" }}>
        <Select
          name="equipment"
          displayEmpty
          value={locationid}
          sx = {{ width: "50%", minWidth: "10px" }}
          onChange={(event) => setLocationid(event.target.value)}
        >
          <MenuItem key={""} value={""}>Select Equipment</MenuItem>
          {locationArray.getAllEquipment(true)
            .filter(location => location.base_id === null)  // should just select the base_id instead
            .sort((a, b) => sortNumAlpha(a.loc_number, b.loc_number))
            .map((location) => (
            <MenuItem key={location.locationid} value={location.locationid}>
              {location.toString(true)}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant='outlined'
          sx={{ width: "20%", minWidth: "40px" }}
          onClick={onAutoAlignEquipment}
        >
          Auto-Align
        </Button>

        <Button
          variant="outlined"
          sx={{  m:3, width: "40px" }}
          onClick={onClearItem}
        >
          X
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <TextField
          type="number"
          name="offset"
          label="Offset(ft)"
          value={offset}
          onChange={(event) => {
            setOffset(event.target.value);
          }}
          sx={{ mt: 1, width: "40%", minWidth: "200px" }}
        />
        <TextField
          type="number"
          name="angle"
          label="Angle(deg)"
          value={angle}
          onChange={(event) => {
            setAngle(event.target.value);
          }}
          sx={{ mt: 1, width: "40%", minWidth: "200px" }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="outlined"
          sx={{ ml: 1, width: "40%", minWidth: "140px" }}
          onClick={onAddEquipment}
        >
          Add/Update Equipment
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: 1, width: "40%", minWidth: "140px" }}
          onClick={onSyncEquipment}
        >
          Sync Lat/Long
        </Button>
      </Box>
    </Box>
  );
}

