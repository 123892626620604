import { useNavigate } from "react-router-dom"
import { useParams, useSearchParams } from 'react-router-dom';

import { LocationBuilder } from '../locationItems/locationBuilder';

import useEquipmentDefaults from '../../hooksDatabase/useEquipmentDefaults';
import useLocationArrayWithOptions from '../../hooksDatabase/useLocationArrayWithOptions';
import { LocationContext } from '../../util/providers/locationContext.jsx';

export function InstanceNew() {
  // const { projID } = useParams();

  return (
    <div>
      <h1 className="head-section-header">New Instance</h1>
    </div>

  )
}

export function InstanceLocations() {
  const navigate = useNavigate();
  const { projID } = useParams();
  const [searchParams] = useSearchParams(); // setSearchParams
  let instanceid = searchParams.get("instanceid")


  function handleRedirectHome(yourHome) {
    // redirect to the root home page
    navigate(`${yourHome}`);
  }

  const [defaultEquipmentList, equipmentOptionsLoading, getDefaultCopy] = useEquipmentDefaults();
  const [
    LocationArray,
    reRenderLocationArray,
    locationArrayLoading,
    handleSaveEquipmentState
  ] = useLocationArrayWithOptions(instanceid, projID, defaultEquipmentList, getDefaultCopy);


  return (
    <LocationContext.Provider value={{ LocationArray, reRenderLocationArray }}>
      <LocationBuilder
        handleRedirect={handleRedirectHome}
        defaultEquipmentList={defaultEquipmentList}
        equipmentOptionsLoading={equipmentOptionsLoading}
        locationArrayLoading={locationArrayLoading}
        handleSaveEquipmentState={handleSaveEquipmentState}
      />
    </LocationContext.Provider>
  )
}