import React from 'react';

import {
  FeatureGroup,
  LayersControl,
  GeoJSON,
} from 'react-leaflet';

import { useTutorial } from '../../../util/providers/useTutorialContext.jsx';

// eslint-disable-next-line no-unused-vars
import { Alignment, AlignmentItemized } from "../../../models/alignment.ts"
import AlignmentEditor from '../alignmentEditor';

/**
 * This function component renders an alignment layer on a map.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Alignment[]} props.alignment - An array of Alignment objects to be rendered on the map.
 * @param {Function} props.handleOpenDialog - A function to handle openning the parent dialog.
 *
 * @returns {JSX.Element} A LayersControl.Overlay component containing the rendered alignments.
 */
export default function AlignmentLayer({
  alignments,
  setAlignments,
  handleOpenDialog,
  editMode,
  checked,
  className = "alignment-group",
  name = "Alignments",
  ...props
}) {

  const { handleProgress } = useTutorial();

  function onSaveAlignment(oldAlignment, newAlignment) {
    const filteredAlignments = alignments.filter(alignmentElement => alignmentElement !== oldAlignment);

    // Create a new AlignmentItemized object
    const newAlignmentItemized = new AlignmentItemized(
      newAlignment.points,
      newAlignment.name,
      newAlignment.alignmentid,
      newAlignment.items,
      newAlignment.bends
    );

    // Update the state with the new array
    setAlignments([...filteredAlignments, newAlignmentItemized]);
  }

  // Function to get the AlignmentItemized object for an alignment
  function getAlignmentItemized(alignment) {
    return AlignmentItemized.fromAlignment(alignment);
  }

  const alignmentFeatures = alignments.flatMap((alignmentElement, index) => {
    // Call the toGeoJSON method to get the FeatureCollection
    const centerline = alignmentElement?.centerLine;
    const rightOfWay = alignmentElement.rightOfWay;
    const pointFeatures = alignmentElement.pointFeatures;

    // Render the FeatureCollection using the GeoJSON component
    return [centerline, rightOfWay, ...pointFeatures].map((feature, featureIndex) => (
      <GeoJSON
        className="tutorial-locations-map-alignment-row-polygon" // does this work?
        key={`${index}-${featureIndex}-${JSON.stringify(feature)}-${Date.now()}`}
        data={feature}
        style={() => ({
          color: 'purple',
        })}
        onEachFeature={(feature, layer) => {
          layer.on({
            click: (e) => {
              if (editMode) return;

              handleProgress(e.target.options.className)
              const alignmentItemizedElement = getAlignmentItemized(alignmentElement);

              handleOpenDialog(
                <AlignmentEditor
                  alignment={alignmentItemizedElement}
                  handleSaveAlignment={(newAlignment) => onSaveAlignment(alignmentElement, newAlignment)}
                />
              );
            },
          });
        }}
      />
    ));
  });

  return (
    <LayersControl.Overlay checked className={className} name={name} {...props}>
      <FeatureGroup>{alignmentFeatures}</FeatureGroup>
    </LayersControl.Overlay>
  );
}