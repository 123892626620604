import { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PostServerData } from '../../util/dbfetch';

import useUserDataContext from '../../util/providers/userDataProvider.jsx';

export default function RegisterOrganization({ onClose }) {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [response, setResponse] = useState('');

  const { refreshData } = useUserDataContext();

  function handleRegisterFailure(response) {
    setResponse("There was a problem registering the organization. \n Please try again or contact the website admin.");
  }

  function handleRegisterSuccess(response) {
    setResponse("");
    refreshData();
    onClose(response);
  }

  function handleRegisterRequest() {
    setResponse('Requesting...');
    PostServerData('organizations/register', {
      organization_name: name,
      organization_desc: description
    },
      handleRegisterSuccess, handleRegisterFailure)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleRegisterRequest();
    }
  };

  return (
    <form>
      <TextField
        id="name"
        label="Organization Name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <br />
      <br />
      <TextField
        id="description"
        label="Description"
        variant="outlined"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <br />
      <br />
      <Button variant="contained" onClick={handleRegisterRequest}>
        Register
      </Button>
      <br />
      <br />
      <Typography variant="body2">{response}</Typography>
    </form>
  );
}