
import React, { useState, useContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import { ConnectionTableRowMUI } from './tableRow.jsx';

import useDragDrop from '../../util/useDragDrop.jsx';
import JoyRideStarter from '../joyRideStarter.jsx';
import ConfirmDialog from '../confirmDialog.jsx';
import { LocationContext } from '../../util/providers/locationContext.jsx';

export function CollapsibleConnectionTabler({ ...props }) {
  const [open, setOpen] = useState(false);

  const handleMinimize = () => {
    setOpen(false);
  }

  return (
    <>
      {open ? (
        <ConnectionTabler onMinimize={handleMinimize} {...props} />
      ) : (
        <>
          <TableContainer className="tutorial-locations-connections" >
            <Table className={"locations-connections"} aria-label="connection table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "5%" }} className="connection-cell-command-header">
                    <Button variant='outlined' onClick={() => setOpen(true)}>
                      <KeyboardArrowDownIcon />
                    </Button>
                  </TableCell>
                  <TableCell sx={{ width: "94%" }} className="connection-cell-loc-header">View Connections</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default function ConnectionTabler({ connectionKeys = [], onMinimize = null }) {
  // Can make a container to reorder the equipment

  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);

  const locList = [
    { id: "", value: "N/A" },
    ...LocationArray.getFilteredLocations()
      .filter((equipment) => !connectionKeys.includes(equipment?.equip_key))
      .map((item) => ({ key: item.locationid, id: item.locationid, value: `${item.loc_number} - ${item.name}` }))
  ];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const steps = [
    {
      target: '.connection-table-row',
      content: "In each row you can change individual properties for the connection",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: '.connection-drag-icon-cell',
      content: "Besides the global sorts in the app bar, you can manually drag and drop locations to reorder.",
      placement: "top",
    },
    {
      target: '.connection-row-select-container-cell, .connection-expand-row-button',
      content: 'Each equipment has unique properties to define itself.  Cable connections and links to other equipment are entirely handled by selecting a SSD.',
      placement: "top",
    },
    {
      target: '.connection-ssd-header',
      content: "Each Connection will have a single Source Side Device(SSD) and single Load Side Device(LSD). Each connection will determine how the entire project is connected by the SSD and LSD.",
      placement: "top",
    },
    {
      target: '.connection-other-header',
      content: 'Other location specific functions may be needed. All UG cables will have a conduit trace as well as conduit properties.',
      placement: "top",
    },
  ];

  const { handleDragStart, handleDrop } = useDragDrop((newOrder) => {
    LocationArray.reorder(newOrder);
    reRenderLocationArray();
  }, "loc12345");


  function handleRowDelete(id) {
    const isReferencedBy = LocationArray.getFilteredLocations()
      .filter(item => item.ssd_id === id || item.lsd_id === id)
      .map(item => item.loc);

    if (isReferencedBy.length > 0) {
      alert("This equipment cannot be deleted until it's unreferrenced from the following locations: " + isReferencedBy.join(", "));
      return;
    }

    handleOpenDialog(
      <ConfirmDialog
        message="Are you sure you want to delete this location?"
        onConfirm={() => {
          LocationArray.flagRemove(id);
          reRenderLocationArray();
          setDialogOpen(false);
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  function handleLocationUpdate(id, newProps) {
    LocationArray.update(id, newProps);
    reRenderLocationArray();
  }


  const listItems = LocationArray.getAllConnections(true)
    .map((item, index) => {
      return (
        <ConnectionTableRowMUI
          key={item.locationid}
          id={item.locationid}
          index={index}
          item={item}
          onRowDelete={handleRowDelete}
          locList={locList}
          onLocationUpdate={handleLocationUpdate}
          onDragStart={handleDragStart}
          onDrop={(e) => handleDrop(e, index,
            LocationArray.getAllLocations(),
            LocationArray.getAllConnections(true)
          )}
          onDragOver={(e) => e.preventDefault()}
        />
      );
    });

  return (
    <>
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>
      <JoyRideStarter steps={steps} anchorVert={-8} disableScrolling={true} />
      <TableContainer>
        <Table aria-label="connection table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "5%" }} className="cell-command-header">
                <Button variant='outlined' onClick={onMinimize}>
                  <KeyboardArrowUpIcon />
                </Button>
              </TableCell>
              <TableCell sx={{ width: "7%" }} className="connection-loc-header">Loc#</TableCell>
              <TableCell sx={{ width: "15%" }} className="connection-type-header">Type</TableCell>
              <TableCell sx={{ width: "15%" }} className="connection-ssd-header">SSD</TableCell>
              <TableCell sx={{ width: "15%" }} className="connection-lsd-header">LSD</TableCell>
              <TableCell sx={{ width: "15%" }} className="connection-other-header">Other</TableCell>
              <TableCell sx={{ width: "5%" }} className="connection-del-header">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          {LocationArray.getAllLocations().length ? (
            listItems
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  None
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>

  );
}

