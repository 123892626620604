import Tutorial from './tutorial.js';
import TutorialPhase from './tutorialPhase.js';


/**
 * `tutorialPhases` is an object that maps phase names to `TutorialPhase` instances.
 * Each `TutorialPhase` represents a phase in the tutorial and contains information about that phase.
 *
 * Properties of a `TutorialPhase`:
 * - `name`: A string that uniquely identifies the phase.
 * - `displayName`: A string that is listed in the Tutorial list
 * - `requiredParameters`: Parameters that need to be stored 
 * - `path`: A function that returns the URL path, this is where the 'Jump To' button will navigate to.
 * - `steps`: An array of objects, each representing a step in the phase. Each step has the following properties:
 *   - `name`: A string that uniquely identifies the step.
 *   - `displayName`: A string that represents the name of the step displayed to the user.
 *   - `description`: A string that describes what the user should do in this step.
 *   - `action`: (Optional) A string that describes the action the user should take to complete the step.
 *                If action is applied, the User must interact with the below className element to progress.
 *                Otherwise there will be a 'Next' button to progress.
 *   - `className`: the class name of the element that must be clicked to progress the tutorial
 *                  This will also definte the element that the 'Jump To' button will navigate to and highlight
 * 
 * 
 * TBD: How do parameters get stored?
 *
 * Example phase: "phase-homepage"
 */


const tutorialPhases = {
  "phase-homepage": new TutorialPhase({
    name: "phase-homepage",
    displayName: "Introduction",
    requiredParameters: [],
    path: () => "/",
    steps: [
      {
        name: "introduce-tutorial",
        displayName: "Tutorial",
        description: `Welcome to this webapp!  This tutorial is a simple introduction to navigating this website.
        You can close this tutorial at any time.  From the user settings you can start this tutorial again.`,
        className: "introduction-tutorial"
      },
      {
        name: "introduce-progress",
        displayName: "Tutorial Basics",
        description: `This tutorial will stick to the top of each page. 
        You can complete each step by clicking the next button or following the "Jump to" button to follow the required action.
        The "Jump to" button will  scroll to the required element, or navigate to the correct page.`,
        action: `Click the "Home" button in the navigation bar to proceed.`,
        className: "introduction-header-progress"
      },
      {
        name: "introduce-navigation",
        displayName: "Navigation",
        description: `The website header has quick links to common webpages including your projects, organizations, and user settings.
        on the left you have navigation buttons to return to previous pages.`,
        className: "introduction-navigation"
      },
      {
        name: "introduce-joyride",
        displayName: "Help Button",
        description: `In most sections there will be a question mark button in the top right corner. 
        Clicking this button will start a guided tour to explain that section.`,
        className: "introduction-joyride"
      },
    ]
  }),
  "phase-project": new TutorialPhase({
    name: "phase-project",
    displayName: "Projects",
    requiredParameters: ['projectid'],
    path: (parameters) => parameters.projectid ? `/project/${parameters.projectid}` : '/project/',
    steps: [
      {
        name: "introduce-projects",
        displayName: "What are projects?",
        description: `Each project has a single sponsor and a single designing organization.
        When you start a project you will initially have both roles.`,
        action: `Click the "Create Demo Project" button to create a demo project.`,
        pathStep: () => "/project/",
        className: "create-demo-project-button"
      },
      {
        name: "overview-project",
        displayName: "Project Overview",
        description: `You were automatically assigned as the editor of this project.
        in "Project Settings and Functions" you can change global settings for the project
        as well as transfer the project or delete it.`,
        action: `Click the "Enable Editing" button in Project Settings. then click the "Save Changes" button to move on.`,
        className: "tutorial-save-changes-button"
      },
      {
        name: "projects-editors-viewers",
        displayName: "Project Roles",
        description: `A project has a single sponsor, editors and viewers.
        Editors are assigned from the design organizations and can make changes to the project
        Viewers can only view the project and its reports. Viewers do not need to be a member of the design organization to view.
        Sponsors are the owner of the project and can view the project as well as reassign the project to a new organization if needed.
        Otherwise they have the same powers as other viewers.`,
        className: "tutorial-project-roles"
      },
      {
        name: "projects-intro-requests-for-proposals",
        displayName: "Requests for Proposals and Price Profiles",
        description: `As a project sponsor, you can look for a designer to work on your project through requests for proposals.  
        Projects can have a price profile applied to calculate costs based on raw quantities.`,
        className: "tutorial-project-rfp-intro"
      },
      {
        name: "projects-instances",
        displayName: "Instances",
        description: `Instances are each individual saved state of the project.
        Each instance can be individually calculated and edited.
        Instances can be grouped into project phases and descriptions.`,
        action: `Click the "Edit Instance" button, change the name and/or description, then save your changes.`,
        className: "tutorial-save-instance-button"
      },
    ]

  }),
  "phase-organization": new TutorialPhase({
    name: "phase-organization",
    displayName: "Organizations",
    requiredParameters: ['organizationid', "projectid"],
    path: (parameters) => {
      if (parameters.organizationid) {
      return `/organizations/${parameters.organizationid}`;
      } else {
      return '/organizations/';
      }
    },
    steps: [
      {
        name: "organization-open",
        displayName: "Open Organization",
        description: `Every project has an Organization who builds the project. The demo will create an organization for you.
        You can view the current organization in the"Project Settings and Functions" section.
        You can navigate directly to this organization with the arrow button here.
        In the upper right you can go to any organization you are a member of.`,
        pathStep: (parameters) => parameters.projectid ? `/project/${parameters.projectid}` : '/project/',
        action: `From "Project Settings and Functions", navigate to this project's organization by clicking the arrow button.`,
        className: "tutorial-parent-org-button"
      },
      {
        name: "organization-manager-overview",
        displayName: "Organization Manager",
        description: `Each organization has a single manager who can reassign projects, manage members, and handle requests for proposals.
        They are also responsible for any billing requirements for the organization.`,
        className: "tutorial-org-overview"
      },
      {
        name: "organization-rfp",
        displayName: "Requests for Proposal",
        description: `Requests for proposals from outside sponsors are listed here.
        In the "Managed By" section you can change the workstreams you are accepting work for.
        New project sponsors will be able to find your organization based on these workstreams.`,
        className: "tutorial-org-rfp"
      },
    ]

  }),
  "phase-locations": new TutorialPhase({
      name: "phase-locations",
      displayName: "Locations Introduction",
      requiredParameters: ['projectid', "instanceid"],
      path: (parameters) => {
      if (parameters.projectid && parameters.instanceid) {
        return `/project/${parameters.projectid}/instance/edit?instanceid=${parameters.instanceid}`;
        } else if (parameters.projectid) {
        return `/project/${parameters.projectid}/instance/edit`;
        } else {
        return '/project/';
        }
      },
      steps: [
        {
          name: "locations-edit",
          displayName: "Opening Locations",
          description: `Return to the project page and click the "Edit Current Locations" button.
          This will open the newest instance.  If you want to revert to a previous instance,
          you can press that instance's "Edit/View Locations" button.`,
          action: `Click the "Edit Current Locations" button to proceed.`,
          pathStep: (parameters) => parameters.projectid ? `/project/${parameters.projectid}` : '/project/',
          className: "tutorial-current-locations-button"
        },
        {
          name: "locations-overview",
          displayName: "Location Editing Overview",
          description: `Here is where the project is designed. Every equipment in the project is handled here.
          A project is broken down into 'Equipment' and 'Connections' which connect equipment.`,
          className: "locations-overview"
        },
        {
          name: "locations-properties",
          displayName: "Location Properties",
          description: `Each location has a series of properties specific to that location.
          These are the primary design drivers for the location. These can be set now and changed later.`,
          className: "tutorial-locations-properties"
        },
        {
          name: "introduce-connections",
          displayName: "Connections",
          description: `Connections determine how the project is connected together.
          Every connection will have a single Source Side Device(SSD), and a single Load Side Device(LSD).
          By assigning SSDs and LSDs, we can automatically determine loading and sizing of equipment.`,
          className: "tutorial-locations-connections"
        },
        {
          name: "introduce-conduit-trace",
          displayName: "Connection Conduit Trace",
          description: `Some locations will need additional configuration. A Conduit Trace is required with
          underground substructures to determine the length and shape of the connection.  If no Conduit Trace
          is provided, one will be generated from the defined alignments.`,
          className: "tutorial-locations-other-options"
        },
        {
          name: "introduce-create-location",
          displayName: "Creating Locations",
          description: `You can create locations by selecting an equipment type in the AppBar.
          Connections can be made by selecting an existing SSD and LSD equipment.`,
          action: `Create a location from the Appbar. it will appear at the bottom of the list.`,
          className: "tutorial-locations-create-location"
        },
      ]
  }),
  "phase-location-map": new TutorialPhase({
    name: "phase-location-map",
    displayName: "Locations Map",
    requiredParameters: ['projectid', "instanceid"],
    path: (parameters) => {
    if (parameters.projectid && parameters.instanceid) {
      return `/project/${parameters.projectid}/instance/edit?instanceid=${parameters.instanceid}`;
      } else if (parameters.projectid) {
      return `/project/${parameters.projectid}/instance/edit`;
      } else {
      return '/project/';
    }},
    steps: [
      {
        name: "locations-map-overview",
        displayName: "Display Map",
        description: `Each location and their connections can be shown on a map.`,
        action: `Open the map by clicking the "Show Map" button.`,
        className: "tutorial-locations-map-overview"
      },
      {
        name: "locations-map-tabs-overview",
        displayName: "Map Tabs",
        description: `The left panel has tabs to add locations directly onto the map.
        You can move, place, and connect equipment; as well as define alignments.
        Modifications are locked until you check the box in each tab.`,
        className: "tutorial-equipment-mapper-tabs"
      },
      {
        name: "locations-map-overview-specific",
        displayName: "Other Map Functions",
        description: `Each equipment can be given a position on the map to show a general layout.
        We can display roads, building outlines, as well as moving and placing equipment directly on the map.`,
        action: `Click the question mark icon to start a guided tour of the map.`,
        className: "tutorial-locations-map-joyride-overview"
      },
    ]
  }),
  "phase-location-alignment": new TutorialPhase({
    name: "phase-location-alignment",
    displayName: "Alignments",
    requiredParameters: ['projectid', "instanceid"],
    path: (parameters) => {
    if (parameters.projectid && parameters.instanceid) {
      return `/project/${parameters.projectid}/instance/edit?instanceid=${parameters.instanceid}`;
      } else if (parameters.projectid) {
      return `/project/${parameters.projectid}/instance/edit`;
      } else {
      return '/project/';
    }},
    steps: [
      {
        name: "locations-map-alignment-overview",
        displayName: "Alignment Overview",
        description: `The Alignment tab is used for defining paths for 
        equipment to connect together.  If a conduit trace is not defined by 
        hand, one will be automatically generated using these alignments.`,
        className: "equipment-mapper-alignment-maker-tab"
      },
      {
        name: "locations-map-alignment-definition",
        displayName: "Alignment Definition",
        description: `Use the name to track which alignments.  Typically you should use the roads name.
        Each created alignment will be listed in the drop down here.
        Width sets the initial width of the Right of Way to either side of the centerline;  
        Each point can be changed later.
        Bisect/Head/Tail modes define how equipment are set relative to each Alignment Point on the road.  
        Conduit Offset is the distance from the centerline of the road to the centerline of any conduits along it.`,
        className: "tutorial-equipment-mapper-tabs"
      },
      {
        name: "locations-map-alignment-create-alignment",
        displayName: "Create an Alignment",
        description: `To create an alignment you must select a starting road centerline.  
        Load Road Data to pull public road profiles in the current map window. 
        The left side of the map has a Polygon and Square to Select elements on the map.`,
        action: `Select one or more road profiles then click ‘Add Road to Alignment’ to continue`,
        className: "tutorial-locations-map-joyride-create-alignment"
      },
      {
        name: "locations-map-alignment-row-polygon",
        displayName: "Right Of Way Polygon",
        description: `A polygon will be created around your selected roads.  
        Click this polygon to edit the alignment point by point.`,
        action: 'Click a Right of Way polygon to continue.',
        className: "tutorial-locations-map-alignment-row-polygon"
      },
      {
        name: "locations-map-alignment-point-map",
        displayName: "Alignment Point Map",
        description: `This map shows all alignment points along the center line and 
        shows the perpendicular lines at each point.  You can add points or edit 
        each point by entering edit mode, the button in the upper right of the map.  
        You can also edit each alignment points alignment mode and widths below.`,
        className: "tutorial-locations-map-alignment-point-map"
      },
      {
        name: "locations-map-alignment-point-equipment",
        displayName: "Alignment Point Equipment",
        description: `The right column shows each alignment point with it’s 
        perpendicular axis.  This will render the relative widths of each point as 
        well as any equipment defined at that point.  You can set equipment at 
        each point with a defined offset and rotated angle.  `,
        className: "tutorial-locations-map-alignment-point-equipment"
      },
    ]
  }),
  "phase-locations-final": new TutorialPhase({
    name: "phase-locations-final",
    displayName: "Location Saving",
    requiredParameters: ['projectid', "instanceid"],
    path: (parameters) => {
    if (parameters.projectid && parameters.instanceid) {
    return `/project/${parameters.projectid}/instance/edit?instanceid=${parameters.instanceid}`;
    } else if (parameters.projectid) {
    return `/project/${parameters.projectid}/instance/edit`;
    } else {
    return '/project/';
    }
    },
    steps: [
      {
        name: "locations-exit",
        displayName: "Save Changes",
        description: `Make any changes you want to the project now.
        Note that if no changes are made, a new instance will not be created.`,
        action: `When Ready, click the "Save Locations" button to save your changes.`,
        className: "tutorial-post-data-button"
      },
    ]
  }),
  "phase-project-calculations": new TutorialPhase({
    name: "phase-project-calculations",
    displayName: "Project Calculations",
    requiredParameters: ["projectid"],
    path: (parameters) => parameters.projectid ? `/project/${parameters.projectid}` : '/project/',
    steps: [
      {
        name: "project-run-calculation",
        displayName: "Run Calculations",
        description: `Each saved instance of the project can be calculated individually.
        Calculations should only take a few seconds to complete. You can always leave the page and come back.`,
        action: `Click the "Run Calculations" button to start the calculation process.`,
        className: "tutorial-run-calculations-button"
      },
      {
        name: "project-calculation-report",
        displayName: "Download report",
        description: `The "Download Report" button will download a report of the calculation results; 
        you may need to refresh the page.  For this tutorial these calculations are using generalized data.  
        Only registered organizations can use your public utility's proprietary data.`,
        action: `Click the "Download Reports" button. You may need to refresh the page.`,
        className: "tutorial-download-report-button"
      },
      {
        name: "project-calculation-options",
        displayName: "Calculation Options",
        description: `If there are conflicts during calculation, the "Resolve Options" will activate.
        Click this to navigate to a page to select an option from a reduced list based on the set location properties.
        These options will be saved and used for future calculations.`,
        className: "tutorial-options-button"
      },
      {
        name: "project-calculation-map",
        displayName: "Abstract Location Map",
        description: `A simple map of the project is available to view.
        This map shows how each equipment is connected with some calculation details
        At the top will show the distribution connections. Transformers and their service networks are shown after.`,
        action: `Click the "Generate Loc Map" button to proceed.`,
        className: "tutorial-download-map-button"
      },
    ]
  }),
  "phase-project-post-processes": new TutorialPhase({
    name: "phase-project-post-processes",
    displayName: "RFPs and Price Profiles",
    path: (parameters) => parameters.projectid ? `/project/${parameters.projectid}` : '/project/',
    steps: [
      {
        name: "sponsor-designer",
        displayName: "Sponsors and Designers",
        description: `Every project has a single sponsor and a single designer.
        The sponsor owns the project and can access reports and calculations at any time.
        The designer organization will manage updating the project, running calculations, and generating reports.
        In this demo you are currently both the sponsor and designer.`,
        className: "tutorial-sponsor-designer"
      },
      {
        name: "projects-requests-for-proposals-sending",
        displayName: "Requests for Proposals",
        description: `As a sponsor you can send your project to designer organizations for proposals.
        Any organization on this platform can opt in to do design work on specific utilities.`,
        action: `Click the "Open Requests for Proposals" to view organizations to send your project to.`,
        className: "tutorial-project-rfp-final"
      },
      {
        name: "projects-proposal-sending",
        displayName: "Designer Selection",
        description: `Listed here are every organization that has opted in to design for your designated utility.
        Check each you would like to send to, and choose an project instance to send them.
        They will use these to inspect and generate a proposal for your project.
        You can also see any active proposals from organizations you have sent to.`,
        action: `Select an organization or two and click the "Request Quote" button to send your project.
        Note: if you select your own organization, you will be able to view receiving end.`,
        className: "tutorial-request-quote"
      },
      {
        name: "projects-price-profiles",
        displayName: "Price Profiles",
        description: `Price Profiles are itemized lists for specific locations and optionally with specific design drivers.
        Organizations can create a price profile with this tool to return to you for your project.`,
        action: `Click the "View Price Profile" button to view your price profiles.`,
        className: "tutorial-project-price-profiles"
      },
      {
        name: "projects-price-profiles-breakdown",
        displayName: "Price Profiles Breakdown",
        description: `You can view all your saved price profiles and all of your organization's price profiles.
        in each equipment section you can individually price based on some properties.`,
        action: `For any equipment click "Add New Profile", select one or more parameters, and click "Add Profile".`,
        pathStep: (parameters) => parameters.projectid ? `/project/${parameters.projectid}/price-profile?instanceid=${parameters.instanceid}` : '/project/',
        className: "tutorial-project-price-profile-add"
      },
    ],
  }),
}


export const tutorials = [
  new Tutorial(
    'comprehensive-tutorial',
    "Comprehensive Tutorial",
    [
      tutorialPhases['phase-homepage'].overridePath(() => "/"),
      tutorialPhases['phase-project'], //.overrideStep('projects-intro-requests-for-proposals', { description: `Request for Proposals and price profiles will be covered at the end of this tutorial. Carry on for now.`}),
      tutorialPhases['phase-organization'], //  .overrideStep('organization-manager-overview', { displayName: 'New Manager DisplayName' }),
      tutorialPhases['phase-locations'],
      tutorialPhases['phase-location-map'],
      tutorialPhases['phase-location-alignment'],
      tutorialPhases['phase-locations-final'],
      tutorialPhases['phase-project-calculations'],
      tutorialPhases['phase-project-post-processes'],
    ],
    'This completes the comprehensive tutorial. You can always start this tutorial again in user settings.  Close this tutorial or begin another.'
  ),
  new Tutorial(
    'initialize-tutorial',
    'Introductions',
    [
      tutorialPhases['phase-homepage'].overridePath(() => "/"),
    ],
    `This covers the essential navigation of the webapp. Choose another tutorial to learn more, or dismiss this tutorial.
      Remember that you can always start this tutorial again in user settings.`
  ),
  // more tutorials...
];