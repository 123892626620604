import React from "react";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';



import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import { PostServerData, DeleteServerData } from '../../util/dbfetch';
import useSnackbarContext from '../../util/providers/snackbarProvider';


export default function TransferInvite({ invite, onDeleteInvite }) {

  const isExpired = (
    () => {
      const expirationDate = new Date(invite.expiration).toLocaleString();
      const todayDate = new Date().toLocaleString();
      return invite.useCount <= 0 || todayDate < expirationDate;
    }
  )();

  const handleDelete = () => {
    onDeleteInvite(invite.transfer_code);
    DeleteServerData(`transfers/${invite.transfer_code}/delete`,
      () => { },
      (err) => null
    );
  }


  return (
    <React.Fragment key={invite.transfer_code}>
      <Typography variant="h2" sx={{ p: 2, pb: 0, mx: 2, mt: 2 }}>
        Transfer Code {invite.transfer_code}
      </Typography>

      <Paper elevation={4} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <Box className="invite-info" sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Typography variant="body2">
              Sending User: {invite.user.username}
            </Typography>
            <Typography variant="body2">
              Expiration: {new Date(invite.expiration).toLocaleString()}
            </Typography>
            <Typography variant="body2">
              Number of Uses Left: {invite.useCount}
            </Typography>

          </Box>
          <Box className="invite-buttons">
            <IconButton onClick={() => handleDelete()}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>

      {/* Project Details */}
      <Accordion sx={{ mb: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", align: "left" }}>
            <Typography variant="body1">Requests and Projects affected:</Typography>
            {!isExpired && invite?.recipients && <Typography color="text.secondary" variant="body2" sx={{ pl: 2 }}>
              Pending Requests: {invite.recipients.map((recipient) => recipient?.user?.display_name || recipient?.user?.username).join(', ')}
            </Typography>}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isExpired ? (
            <ExpiredInvite invite={invite} />
          ) : (
            <PendingApprovals invite={invite} />
          )}

          <Grid container spacing={2}>
            {invite.projects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project.projectid}>
                <ProjectCard project={project} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}


function ExpiredInvite({ invite }) {
  return (
    <Paper elevation={10} sx={{ display: "flex", flexDirection: "column", alignItems: "left", align: "left", p: 1, mb: 2 }}>
      <Typography variant="body2" sx={{ pl: 2 }}>This transfer has expired. Below are the recipients</Typography>
      <List sx={{ width: '100%', maxWidth: 360 }}>
        {invite.recipients.map((recipient) => (
          recipient.approved && (
            <ListItem key={recipient.user.userid} disablePadding>
              <ListItemText primary={recipient.user.username} secondary={recipient.organization ?
                `Name: ${recipient.user.display_name} of ${recipient.organization.organization_name}` :
                `Name: ${recipient.user.display_name}`} sx={{ pl: 2 }} />
            </ListItem>
          )
        ))}
      </List>
    </Paper>
  );
}


function PendingApprovals({ invite }) {
  const { openSnackbar } = useSnackbarContext();

  function handleAcceptTransfer(userid, organizationid = null) {
    const data = {
      transfer_code: invite.transfer_code,
      userid: userid,
      ...(organizationid && { organizationid: organizationid })
    };

    PostServerData(`transfers/${invite.transfer_code}/approve`,
      data,
      () => { openSnackbar('Approval Successful'); },
      (response) => { openSnackbar(response); },
    );
  }


  function handleDenyTransfer(userid, organizationid = null) {
    const data = {
      transfer_code: invite.transfer_code,
      userid: userid,
      ...(organizationid && { organizationid: organizationid })
    };

    PostServerData(`transfers/${invite.transfer_code}/deny`,
      data,
      () => { openSnackbar('Denied Successfully'); },
      (response) => { openSnackbar(response); },
    );
  }


  return (
    <Paper elevation={10}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "left", justifyContent: "space-between", pb: 2 }}>
        <Box sx={{ py: 2, pl: 4 }}>
          <Typography variant="body1" sx={{ pl: 2 }}>Pending Approvals</Typography>
          <List sx={{ width: '100%', maxWidth: 360 }}>
            {invite.recipients.map((recipient) => (
              recipient.approved === null && (
                <ListItem key={recipient.user.userid} disablePadding>
                  <Button variant="outlined" onClick={() => handleAcceptTransfer(recipient.user?.userid, recipient?.organization?.organizationid)} sx={{ pl: 2 }}>
                    Approve
                  </Button>
                  <Button variant="outlined" onClick={() => handleDenyTransfer(recipient.user?.userid, recipient?.organization?.organizationid)} sx={{ ml: 2 }}>
                    Deny
                  </Button>
                  <ListItemText primary={recipient.user.username} secondary={recipient.organization ? `Name: ${recipient.user.display_name || recipient.user.username} of ${recipient.organization.organization_name}` : `Name: ${recipient.user.display_name || recipient.user.username}`} sx={{ pl: 2 }} />
                </ListItem>
              )
            ))}
          </List>
        </Box>
        <Box sx={{ py: 2, pr: 10 }}>
          <Typography variant="body1" sx={{ pl: 2 }}>Denied Approvals</Typography>
          <List sx={{ width: '100%', maxWidth: 360 }}>
            {invite.recipients.map((recipient) => (
              recipient.approved === false && (
                <ListItem key={recipient.user.userid} disablePadding>
                  <ListItemText primary={recipient.user.username} secondary={recipient.organization ? `Name: ${recipient.user.display_name || recipient.user.username} of ${recipient.organization.organization_name}` : `Name: ${recipient.user.display_name || recipient.user.username}`} sx={{ pl: 2 }} />
                </ListItem>
              )
            ))}
          </List>
        </Box>
      </Box>
    </Paper>
  );
}



function ProjectCard({ project }) {

  return (
    <Card key={project.projectid} sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="body1">{project.proj_name}</Typography>
        <Typography variant="body2">
          Roles: {project.roles.join(', ')}
        </Typography>
      </CardContent>
    </Card>
  )
}