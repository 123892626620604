
import { Navigate, Route, Routes } from "react-router-dom";

import UserSettings from "./userSettings";

import TransferResponsibilityUser from '../../components/transfers/transferResponsibilityUser';
import UserTransfers from '../../components/transfers/userTransfers';

export default function UserRoute() {
  // root/project

  return (
    <Routes>
      <Route index element={<UserSettings />} />
      <Route path = "/transfer" element = {<TransferResponsibilityUser />} />
      <Route path = "/transfers" element = {<UserTransfers />} />
      {/* <Route path="new" element={<CreateProject />} /> */}
      <Route path="*" element={<Navigate to="/user" />} />
    </Routes>
  );
}