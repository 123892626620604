import { Feature, Point, GeoJsonProperties } from 'geojson';

import { bearing, destination } from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';

import { GeoPoint } from '../util/geoSpatial.ts';


/**
 * The BearingMode class provides static methods and properties to calculate the bearing of a point in an alignment.
 */
export class BearingMode {
  static Bisect = 'bisect';
  static HeadAngle = 'headAngle';
  static TailAngle = 'tailAngle';

  /**
   * Calculates the bearing of a point in an alignment based on the provided mode.
   *
   * @param {string} mode - The mode to use for the bearing calculation.
   * @param {AlignmentPerpendicular} currentPoint - The current point in the alignment.
   * @param {AlignmentPerpendicular} [previousPoint] - The previous point in the alignment.
   * @param {AlignmentPerpendicular} [nextPoint] - The next point in the alignment.
   * @returns {number} The calculated bearing.
   * 
   * 
   * Note: North is 0 degrees, east is 90 degrees, South is 180 degrees, and West is 270 degrees.
   */
  static getBearing(mode: string, currentPoint: AlignmentPerpendicular, previousPoint?: AlignmentPerpendicular, nextPoint?: AlignmentPerpendicular): number {
    let bearing0: number = 0;

    if (!previousPoint && nextPoint) {
      return bearing([currentPoint.lon, currentPoint.lat], [nextPoint.lon, nextPoint.lat]);
    }

    if (!nextPoint && previousPoint) {
      return bearing([previousPoint.lon, previousPoint.lat], [currentPoint.lon, currentPoint.lat]);
    }

    switch (mode) {
      case BearingMode.HeadAngle:
        if (previousPoint) {
          bearing0 = bearing([previousPoint.lon, previousPoint.lat], [currentPoint.lon, currentPoint.lat]);
        }
        break;
      case BearingMode.TailAngle:
        if (nextPoint) {
          bearing0 = bearing([currentPoint.lon, currentPoint.lat], [nextPoint.lon, nextPoint.lat]);
        }
        
        break;
      case BearingMode.Bisect:
        if (previousPoint && nextPoint) {
          const headAngle = bearing([previousPoint.lon, previousPoint.lat], [currentPoint.lon, currentPoint.lat]);
          const tailAngle = bearing([currentPoint.lon, currentPoint.lat], [nextPoint.lon, nextPoint.lat]);
  
          // Convert angles to radians
          const headAngleRad = headAngle * Math.PI / 180;
          const tailAngleRad = tailAngle * Math.PI / 180;
  
          // Convert angles to vectors
          const headVector = [Math.cos(headAngleRad), Math.sin(headAngleRad)];
          const tailVector = [Math.cos(tailAngleRad), Math.sin(tailAngleRad)];
  
          // Add vectors
          const bisectVector = [headVector[0] + tailVector[0], headVector[1] + tailVector[1]];
  
          // Convert vector to angle in radians
          const bisectAngleRad = Math.atan2(bisectVector[1], bisectVector[0]);
  
          // Convert radians to degrees
          bearing0 = bisectAngleRad * 180 / Math.PI;
        }
        break;
    }
    return bearing0;
  }
}

interface IWidth {
  left: number;
  right: number;
}

/**
 * The Width class represents the width of a point in an alignment.
 *
 * @property {number} left - The width on the left side of the point.
 * @property {number} right - The width on the right side of the point.
 */
class Width implements IWidth {
  left: number;
  right: number;

  /**
   * Constructs a new Width object.
   *
   * @param {number | IWidth} width - The width of the point. If a number is provided, it is used for both the left and right widths. If an IWidth object is provided, its left and right properties are used.
   */
  constructor(width: number | IWidth) {
    if (typeof width === 'number') {
      this.left = width;
      this.right = width;
    } else {
      this.left = width.left;
      this.right = width.right;
    }
  }

  isEqual(other: Width | number, epsilon = 0.001): boolean {
    if (typeof other === 'number') {
      return Math.abs(this.left - other) < epsilon && Math.abs(this.right - other) < epsilon;
    }
    return Math.abs(this.left - other.left) < epsilon && Math.abs(this.right - other.right) < epsilon;
  }
}

export interface Item {
  locationid: number; // corresponding locationid
  offset: number; // offset from centerline, -Left, +Right
  angle: number; // angle from front facing alignment, -Left, +Right
}

export interface IAlignmentLatLon {
  lat: number;
  lon: number;
}

export interface IAlignmentPydantic extends IAlignmentLatLon {
  // This is the interface for the Pydantic API
  alignmentpointid: string | null;
  nodeOSM: number | null;
  bearingMode: string;
  widthLeft: number;
  widthRight: number;
  items: Item[];
  order: number;
}

interface IAlignmentPerpendicularProperties extends IAlignmentLatLon {
  alignmentpointid: string | null;
  nodeOSM: number | null;
  width: Width;
  bearingMode: string;
}

export interface IAlignmentPerpendicularItemizedProperties extends IAlignmentPerpendicularProperties {
  items: Item[];
}


/**
 * The AlignmentPerpendicular class represents a point in an alignment.
 *
 * @property {number} lat - The latitude of the point.
 * @property {number} lon - The longitude of the point.
 * @property {Width} width - The width of the point.
 * @property {string} bearingMode - The bearing mode of the point.
 * @property {number} [nodeOSM] - The OpenStreetMap node ID of the point.
 */
export class AlignmentPerpendicular extends GeoPoint {
  alignmentpointid: string | null;
  width: Width;
  bearingMode: string;
  nodeOSM: number | null;
  order: number;

  /**
   * Constructs a new AlignmentPerpendicular object.
   *
   * @param {number} lat - The latitude of the point.
   * @param {number} lon - The longitude of the point.
   * @param {number | IWidth} width - The width of the point.
   * @param {number} [nodeOSM] - The OpenStreetMap node ID of the point.
   * @param {string} [bearingMode] - The bearing mode of the point.
   */
  constructor(
    lat: number, 
    lon: number, 
    width: number | IWidth, 
    bearingMode: string = BearingMode.Bisect, 
    alignmentpointid: string | null = null, 
    nodeOSM: number | null = null,
    order: number
  ) {
    super(lon, lat);
    this.width = new Width(width);
    this.bearingMode = bearingMode;
    if (alignmentpointid === null) {
      this.alignmentpointid = `new-${uuidv4()}`;
    } else {
      this.alignmentpointid = alignmentpointid;
    }
    this.nodeOSM = nodeOSM;
    this.order = order;
  }

  static fromPydantic(pydanticModel: IAlignmentPydantic): AlignmentPerpendicular {
    const width  = new Width({
      left: pydanticModel.widthLeft,
      right: pydanticModel.widthRight,
    });
    return new AlignmentPerpendicular(
      pydanticModel.lat, 
      pydanticModel.lon, 
      width, 
      pydanticModel.bearingMode, 
      pydanticModel.alignmentpointid ?? null, 
      pydanticModel.nodeOSM ?? null,
      pydanticModel.order,
    );
  }
  
  
  /**
   * Converts the AlignmentPerpendicular object to a format that can be used with the Pydantic API.
   * TODO: Extract to #ObjectConverter" class. 
   *
   * @returns {any} An object that represents the AlignmentPerpendicular object in a format that can be used with the Pydantic API.
   */
  toPydanticAPI(): IAlignmentPydantic {
    return {
      lat: this.lat,
      lon: this.lon,
      widthLeft: this.width.left,
      widthRight: this.width.right,
      nodeOSM: this.nodeOSM,
      bearingMode: this.bearingMode,
      alignmentpointid: this.alignmentpointid,
      items: [],
      order: this.order,
    };
  }

  pointBearing(prevPoint?: AlignmentPerpendicular, nextPoint?: AlignmentPerpendicular) {
    return BearingMode.getBearing(this.bearingMode, this, prevPoint, nextPoint);
  }

  /**
   * Calculates the left point of the alignment at the specified offset.
   *
   * @param {number} offset - The offset from the current point.
   * @param {AlignmentPerpendicular} [previousPoint] - The previous point in the alignment.
   * @param {AlignmentPerpendicular} [nextPoint] - The next point in the alignment.
   * @returns {Feature<Point, GeoJsonProperties>} A GeoJSON Point feature representing the left point.
   */
  leftPoint(
    offset: number, 
    previousPoint?: AlignmentPerpendicular, nextPoint?: AlignmentPerpendicular
  ): Feature<Point, GeoJsonProperties> {
    const bearing0 = BearingMode.getBearing(this.bearingMode, this, previousPoint, nextPoint);
    const leftPoint = destination(
      [this.lon, this.lat], 
      offset, bearing0 - 90, 
      { units: 'feet' }
    );

    return leftPoint
  }

  /**
   * Calculates the right point of the alignment at the specified offset.
   *
   * @param {number} offset - The offset from the current point.
   * @param {AlignmentPerpendicular} [previousPoint] - The previous point in the alignment.
   * @param {AlignmentPerpendicular} [nextPoint] - The next point in the alignment.
   * @returns {Feature<Point, GeoJsonProperties>} A GeoJSON Point feature representing the right point.
   */
  rightPoint(
    offset: number, 
    previousPoint?: AlignmentPerpendicular, nextPoint?: AlignmentPerpendicular
  ): Feature<Point, GeoJsonProperties> {
    const bearing0 = BearingMode.getBearing(this.bearingMode, this, previousPoint, nextPoint);

    const rightPoint = destination(
      [this.lon, this.lat], 
      offset, bearing0 + 90, 
      { units: 'feet' }
    );

    return rightPoint
  }

  addItem(item: Item) {
    // Do nothing
    console.log('AlignmentPerpendicular.addItem() not implemented', this, item);
  }
  
  isEqualLatLon(lat: number, lon: number, epsilon = 0.00001): boolean {
    return Math.abs(this.lat - lat) < epsilon && Math.abs(this.lon - lon) < epsilon;
  }

  getProperties(): IAlignmentPerpendicularProperties {
    return {
      lat: this.lat,
      lon: this.lon,
      width: this.width,
      bearingMode: this.bearingMode,
      alignmentpointid: this.alignmentpointid ?? null,
      nodeOSM: this.nodeOSM ?? null,
    };
  }

  isEqualProperties(other: IAlignmentPerpendicularProperties): boolean {
    return this.width.isEqual(other.width) && 
           this.bearingMode === other.bearingMode && 
           this.alignmentpointid === other.alignmentpointid && 
           this.nodeOSM === other.nodeOSM;
  }

  distanceTo(other: AlignmentPerpendicular): number {
    return Math.sqrt( (this.lon - other.lon)**2 + (this.lat - other.lat)**2 );
  }

  isEqual(other: AlignmentPerpendicular): boolean {
    // Check if lat/lon matches
    const latLonMatch = this.isEqualLatLon(other.lat, other.lon)
    
    // Check if all other object properties are the same
    const propertiesMatch = this.isEqualProperties(other.getProperties());
    
    return latLonMatch && propertiesMatch;
  }
}

export class AlignmentPerpendicularItemized extends AlignmentPerpendicular {
  items: Item[];
  bends: any[];

  constructor(
    lat: number, 
    lon: number, 
    width: number | IWidth, 
    bearingMode: string = BearingMode.Bisect, 
    alignmentpointid: string | null = null, 
    nodeOSM: number | null = null,
    items: Item[] = [],
    order: number
  ) {
    super(lat, lon, width, bearingMode, alignmentpointid, nodeOSM, order);
    this.items = items;
    this.bends = [];

  }

  static fromAlignmentPerpendicular(alignmentPerpendicular: AlignmentPerpendicular): AlignmentPerpendicularItemized {
    return new AlignmentPerpendicularItemized(
      alignmentPerpendicular.lat, 
      alignmentPerpendicular.lon, 
      alignmentPerpendicular.width, 
      alignmentPerpendicular.bearingMode, 
      alignmentPerpendicular.alignmentpointid, 
      alignmentPerpendicular.nodeOSM,
      [],
      alignmentPerpendicular.order,
      );
  }

  static fromPydantic(pydanticModel: IAlignmentPydantic): AlignmentPerpendicularItemized {
    const baseAlignment = super.fromPydantic(pydanticModel);
    return new AlignmentPerpendicularItemized(
      baseAlignment.lat, 
      baseAlignment.lon, 
      baseAlignment.width, 
      baseAlignment.bearingMode, 
      baseAlignment.alignmentpointid, 
      baseAlignment.nodeOSM, 
      pydanticModel.items,
      baseAlignment.order,
    );
  }

  toPydanticAPI(): any {
    const pydanticAPI = super.toPydanticAPI();
    pydanticAPI.items = this.items;
    return pydanticAPI;
  }

  getProperties(): IAlignmentPerpendicularItemizedProperties {
    return {
      ...super.getProperties(),
      items: this.items,
    };
  }

  addItem(item: Item) {
    this.items = [...this.items, item];
  }

  removeItem(locationid: number) {
    this.items = this.items.filter(item => item.locationid !== locationid);
  }

}