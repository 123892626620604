import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { tutorials } from './tutorials';
import TutorialBody from './tutorialBody';
import { useTutorial } from '../../util/providers/useTutorialContext';


export default function TutorialLoader({ currentTutorial, handleStopTutorial, updateTutorialStateParameters }) {

  const { tutorialState, handleStartTutorial, handleProgress } = useTutorial();

  const [selectedTutorial, setSelectedTutorial] = useState({ name: '', phases: [] });
  const [previewTutorial, setPreviewTutorial] = useState(null);

  if (!tutorialState) {
    setSelectedTutorial({ name: '', phases: [] });
    return null;
  }


  const onStartTutorial = (selectedTutorial) => {
    setPreviewTutorial(null);
    handleStartTutorial(selectedTutorial.name)
  }


  const handleTutorialChange = (event) => {
    const tut = tutorials.find(tutorial => tutorial.name === event.target.value);
    setSelectedTutorial(tut);

    if (event.target.value === currentTutorial.name) {
      setPreviewTutorial(null);
    } else {
      setPreviewTutorial(tut)
    }
  };

  return (
    <Paper elevation={3} >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="select-tutorial-helper-label">Select Tutorial</InputLabel>
            <Select
              labelId="select-tutorial-label"
              id="select-tutorial"
              value={selectedTutorial.name}
              label="Select Tutorial"
              onChange={handleTutorialChange}
            >
              <MenuItem disabled key={""} value={""} sx={{ fontStyle: 'italic' }}>Select a Tutorial</MenuItem>
              {tutorials.map((tutorial, index) => {
                return (
                  <MenuItem key={index} value={tutorial.name}>{tutorial.displayName}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" disabled={!selectedTutorial.name} onClick={() => onStartTutorial(selectedTutorial)}>{previewTutorial ? "Start" : "Restart"}</Button>
        </Box>
        <Button variant="outlined" color="primary" onClick={handleStopTutorial}>X</Button>
      </Box>

      <TutorialBody
        currentTutorial={currentTutorial}
        tutorialState={tutorialState}
        onProgress={handleProgress}
        previewTutorial={previewTutorial}
      />
    </Paper>
  );
}