import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';

import { UseServerDataResponse, DeleteServerData, PostServerData } from "../../util/dbfetch";
import { Box, Card, Dialog, Stack, TextField, Typography } from '@mui/material';


import { useTutorial } from '../../util/providers/useTutorialContext';
import ConfirmDialog from '../confirmDialog';
import useSnackbarContext from '../../util/providers/snackbarProvider';

export default function InstanceContent({ instance, projID, onCalculationInitialized, onSuccessDeleteInstance, onUpdateInstances }) {

  const dateUpdate = new Date(instance.date_update);

  const navigate = useNavigate();
  const { handleProgress } = useTutorial();
  const { openSnackbar } = useSnackbarContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const [editMode, setEditMode] = useState(false);
  const [instanceDesc, setInstanceDesc] = useState(instance.instance_desc);
  const [projectPhase, setProjectPhase] = useState(instance.project_phase);


  function handleEditInstance() {
    setEditMode(true);
  }

  function handleSaveInstance(event) {
    // Save the updated instance description to the server
    const url = `instance/${instance.instanceid}/update`;
    const data = { instance_desc: instanceDesc };
    const data2 = {
      instance_desc: instanceDesc,
      project_phase: projectPhase,
    };

    const onSuccess = (resp) => {
      setEditMode(false)
      onUpdateInstances(resp.data)
    };

    if (projectPhase !== instance.project_phase) {
      handleOpenDialog(
        <ConfirmDialog
          title="Change Project Phase"
          message="Are you sure you want to change the project phase? This will create a new phase from this instance."
          onConfirm={() => {
            PostServerData(url, data2, onSuccess, () => openSnackbar("Error updating instance"));
            setDialogOpen(false);
          }}
          onCancel={() => setDialogOpen(false)}
        />
      );
    } else {
      PostServerData(url, data, onSuccess, () => openSnackbar("Error saving instance"));
    }

    handleProgress(event.target.className)
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  function handleCancelEdit() {
    setInstanceDesc(instance.instance_desc);
    setProjectPhase(instance.project_phase);
    setEditMode(false);
  }

  function handleCalculation(event) {
    const url = `instance/${instance.instanceid}/calculate`;
    const onSuccess = (response) => {
      onCalculationInitialized(instance.instanceid, response);
      handleProgress(event.target.className)
    }

    PostServerData(url, null, onSuccess, () => openSnackbar("Error running calculations"));

  }

  function handleResetCalculation(event) {
    handleOpenDialog(
      <ConfirmDialog
        title={`Reset Calculations for Instance ${instance.instanceid}`}
        message={`Are you sure you want to reset calculations for instance ${instance.instanceid}?\nThis will delete all stored reports for this instance. This action cannot be undone!`}
        onConfirm={() => {
          const url = `instance/${instance.instanceid}/reset-calculation`;
          const onSuccess = (response) => {
            onCalculationInitialized(instance.instanceid, response, "initialization");
            handleProgress(event.target.className)
          }
          PostServerData(url, null, onSuccess, () => openSnackbar("Error resetting calculations"));
          setDialogOpen(false);
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }

  function handleMapError(err) {
    if (err.response.status === 404) {
      openSnackbar("There was an error generating a map");
    } else {
      openSnackbar(err);
    }
  }

  function handleGetMap(event) {
    const url = `instance/${instance.instanceid}/ssd_map`;
    const onSuccess = (response) => {
      const contentType = response.headers["content-type"];
      // const blob = response.data.blob();
      const blob = new Blob([response.data], { type: contentType });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `map_${instance.instanceid}.${contentType === "application/pdf" ? "pdf" : "zip"}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      handleProgress(event.target.className)
    };
    UseServerDataResponse(url, onSuccess, () => { }, (err) => { handleMapError(err) }, null);
  }

  function handleGetReport(event) {
    const url = `instance/${instance.instanceid}/report`;
    const onSuccess = (response) => {
      const contentType = response.headers["content-type"];
      // const blob = response.data.blob();
      const blob = new Blob([response.data], { type: contentType });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `report_${instance.instanceid}.${contentType === "application/pdf" ? "pdf" : "zip"}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      handleProgress(event.target.className)
    };
    UseServerDataResponse(url, onSuccess, () => { }, (err) => { handleReportError(err) }, null);
  }

  function handleReportError(err) {
    if (err.response.status === 404) {
      openSnackbar("No report found for this instance\n" +
        "Consider (re)running the calculations");
    } else {
      openSnackbar(err);
    }
  }

  function handleDelete() {
    handleOpenDialog(
      <ConfirmDialog
        message={`Are you sure you want to delete instance ${instance.instanceid}?\nThis will delete all stored reports for this instance. This action cannot be undone!`}
        onConfirm={() => {
          const url = `instance/${instance.instanceid}/delete`;
          DeleteServerData(url, onSuccessDeleteInstance, openSnackbar);
          setDialogOpen(false);
        }}
        onCancel={() => setDialogOpen(false)}
      />
    );
  }

  const handleEditViewLocations = (event) => {
    handleProgress(event.target.className, { instanceid: instance.instanceid })
    navigate(`/project/${projID}/instance/edit?instanceid=${instance.instanceid}`);
  };


  return (
    <Card className="instance-list-item">
      <Dialog maxWidth="md" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>

      <Stack direction="row">
        <Box sx={{ width: "90%", display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "1rem", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ flexGrow: 1 }}>Updated: {dateUpdate.toLocaleDateString()}</Typography>
            {editMode ? (
              <>
                <TextField
                  label="Project Phase"
                  value={projectPhase}
                  onChange={(e) => setProjectPhase(e.target.value)}
                />
                <TextField value={instanceDesc} onChange={(e) => setInstanceDesc(e.target.value)} />
              </>
            ) : (
              <Typography sx={{ flexGrow: 1 }}>Details: {instanceDesc}</Typography>
            )}
            {editMode ? (
              <Stack direction="row">
                <Button className="save-instance-button tutorial-save-instance-button" variant="contained" onClick={handleSaveInstance}>Save</Button>
                <Button className="cancel-edit-button" variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
              </Stack>
            ) : (
              <Button className={editMode ? "edit-instance-button" : "edit-instance-button tutorial-save-instance-button"} variant="outlined" onClick={handleEditInstance}>Edit Instance</Button>
            )}
            <Button
              className="tutorial-current-locations-button edit-locations-button"
              variant="outlined"
              onClick={handleEditViewLocations}
            >
              Edit/View Locations
            </Button>
          </Box>
          <Box sx={{ width: "40%", minWidth: "300", my: 1 }}>
            {instance.instance_stage === "initialization" || instance.instance_stage === "calculation start" ? (
              <Button className="tutorial-run-calculations-button run-calculations-button" variant="contained" onClick={handleCalculation}>Run Calculations</Button>
            ) : (
              <Button className="tutorial-run-calculations-button reset-calculations-button" variant="contained" onClick={handleResetCalculation}>Reset Calculations</Button>
            )}

            {instance.choices ? (
              <Button className="tutorial-options-button options-button" variant="contained" component={RouterLink} to={`/project/${projID}/instance/${instance.instanceid}/choices`}>Resolve Options</Button>
            ) : (
              <Button className="tutorial-options-button options-button" variant="contained" disabled>Resolve Options</Button>
            )}

            <Button className="tutorial-download-map-button download-map-button" variant="outlined" onClick={handleGetMap}>Generate Loc Map</Button>

            {instance.path ? (
              <Button className="tutorial-download-report-button download-report-button" variant="outlined" onClick={handleGetReport}>Download Report</Button>
            ) : (
              <Button className="tutorial-download-report-button download-report-button" variant="outlined" disabled>Download Report</Button>
            )}
          </Box>
        </Box>
        <Button className="delete-instance-button" variant="delete" color="secondary" onClick={handleDelete}>X</Button>
      </Stack>
    </Card>
  );
}