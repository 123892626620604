import React, { useState, useEffect, useContext, useRef } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import { connectionType } from '../../models/util/connectionType.js';

import MyMapContainer from './map.jsx';
import ConnectionMaker from './tabs/connectionMaker.jsx';
import IconPicker from './tabs/iconPicker.jsx';
import EquipmentPlacer from './tabs/equipmentPlacer.jsx';
import AlignmentMaker from './tabs/alignmentMaker.jsx';

import ConfirmDialog from '../confirmDialog.jsx';
import PoleConnectionManager from './poleConnectionManager.jsx';

import { useTutorial } from '../../util/providers/useTutorialContext.jsx';

import JoyRideStarter from '../joyRideStarter.jsx';
import useSnackbarContext from '../../util/providers/snackbarProvider.jsx';
import { LocationContext } from '../../util/providers/locationContext.jsx';


/**
 * EquipmentMapper is a component that allows users to interact with equipment on a map.
 * Users can move existing equipment, place new equipment, create connections between equipment, and create alignments.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onGenerateEquipment - A function to generate new equipment.
 *
 * @returns {JSX.Element} A component that allows users to interact with equipment on a map.
 */
function EquipmentMapper({
  onGenerateEquipment,
  alignmentArray,
  setAlignmentArray,
  handleSaveAlignmentArray,
  alignmentArrayLoading,
  defaultEquipmentList,
}) {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [selectedIcon, setSelectedIcon] = useState('');
  const selectedIconRef = useRef(selectedIcon);

  const mapRef = useRef(null);

  const { handleProgress } = useTutorial();
  const { openSnackbar } = useSnackbarContext();


  const { LocationArray, reRenderLocationArray } = useContext(LocationContext);


  const steps = [
    {
      target: '.equipment-mapper-tabs',
      content: 'These tabs enable adding location data directly to a map.',
      placement: "right",
      disableBeacon: true,
    },

    // {Map Tab generals}
    {
      target: '.equipment-mapper-edit-mode-label',
      content: "Edits are initially locked.  Click here to unlock to enable changes. Keep the lock on to simply scroll and pan the map.",
      placement: "right",
    },

    // {Map Tab Specifics}

    {
      target: '.equipment-mapper-loc-placer',
      content: 'Place Existing Equipment into the Map.  Reselecting a placed location will move it and any connections to it',
      placement: "right",
      disableBeacon: true,
    },
    {
      target: '.equipment-mapper-icon-picker',
      content: "Create New Locations directly on the Map. Add equipment to the project along with a latitude and longitude",
      placement: "right",
    },
    {
      target: '.equipment-mapper-connection-maker',
      content: "Generate connections between equipment by clicking two equipment",
      placement: "right",
    },
    {
      target: '.equipment-mapper-alignment-maker',
      content: "Create Alignments along the road for automated underground connections.",
      placement: "right",
    },

    // {map specifics}

    {
      target: '.map-display-container',
      content: "This Map You can control the zoom with the buttons on the left or the mouse wheel. On the Right you can display different layers on the map.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: '.create-building-layer',
      content: "Click here to pull OpenStreetMap data for buildings.  This button only works for the closest 5 zoom levels.",
      placement: "top",
    },
    {
      target: '.create-road-layer',
      content: "Click here to pull OpenStreetMap data for roads.  This button only works for the closest 5 zoom levels. ",
      placement: "top",
    },
    {
      target: '.zoom-to-input-controller',
      content: "Enter an address to zoom to that location.  Alternatively, use a latitude and longitude pair.",
      placement: "right",
    },
  ];

  useEffect(() => {
    selectedIconRef.current = selectedIcon;
  }, [selectedIcon]);

  /**
   * This useEffect hook resets the component's state when the editMode changes.
   * If editMode is false, it will reset selectedIcon, newEquipment, selectedObjects, and editMode states.
   */
  useEffect(() => {
    // reset states when editMode changes
    if (!editMode) {
      setSelectedIcon(null);
      setSelectedObjects([]);
      setEditMode(false)
    }
  }, [editMode])


  const handleTabChange = (event, newValue) => {

    setSelectedTab(newValue);
    setEditMode(false)
  };

  function handleMapClickInTab(event, objectSSD = null, objectLSD = null) {
    const lat = event.latlng.lat;
    const lng = event.latlng.lng;

    if (selectedTab === 0) {

      if (editMode) {
        const selectedEquipmentIds = selectedObjects
          .filter(selectedEquipment => LocationArray.contains(selectedEquipment))
          .map(selectedEquipment => selectedEquipment.locationid);

        if (selectedEquipmentIds.length === 1) {
          LocationArray.update(selectedEquipmentIds[0], {
            position: { lat: lat, lng: lng }
          });
          reRenderLocationArray();

        } else if (selectedEquipmentIds.length > 1) {
          openSnackbar('Multiple Equipment Selected, Please Select One to Relocate.');
        }
      }

    } else if (selectedTab === 1) {
      // Place Equipment (icon)
      if (editMode && selectedIconRef.current !== null) {
        const newEquip = onGenerateEquipment(selectedIconRef.current);

        newEquip.position = { lat: lat, lng: lng };

        LocationArray.add(newEquip);
        reRenderLocationArray();
      } else {

      }

    } else if (selectedTab === 2) {
      // Connect equipment
      if (editMode && objectSSD && objectLSD) {

        let cableType = null;
        let finSSD = null;
        let finLSD = null;
        // if poles, handle differently
        if (objectSSD.equip_key === 'pole' && objectLSD.equip_key === 'pole') {
          openSnackbar('Open `Pole Connector` for poles');
          return;
        } else if (objectSSD.equip_key === 'pole' || objectLSD.equip_key === 'pole') {
          const nonPoleObject = objectSSD.equip_key === 'pole' ? objectLSD : objectSSD;
          const poleObject = objectSSD.equip_key === 'pole' ? objectSSD : objectLSD;
          const poleSSD = objectSSD.equip_key === 'pole' ? true : false;

          const poleDependentLocs = LocationArray.getDependents(poleObject.locationid);


          let depSelectedLoc = null;
          for (let i = 0; i < poleDependentLocs.length; i++) {
            const poleDependentLoc = poleDependentLocs[i];
            cableType = connectionType(nonPoleObject, poleDependentLoc);

            if (cableType) {
              // return first valid connection
              depSelectedLoc = poleDependentLoc;
              break
            } else {
              // try the reverse
              cableType = connectionType(poleDependentLoc, nonPoleObject);
              if (cableType) {
                // return first valid connection
                depSelectedLoc = poleDependentLoc;
                break
              }
            }
          }
          // if no valid connection, mention missing pole dependents
          if (!depSelectedLoc) {
            openSnackbar('No valid dependents on pole: ' + poleObject.loc_number);
            return null;
          }

          // select the finSSD and finLSD as appropriate
          if (poleSSD) {
            finSSD = depSelectedLoc;
            finLSD = nonPoleObject;
          } else {
            finSSD = nonPoleObject;
            finLSD = depSelectedLoc;
          }



        } else {
          cableType = connectionType(objectSSD, objectLSD)
          finSSD = objectSSD;
          finLSD = objectLSD;

        }

        // create finalSSD and finalLSD

        if (cableType === null) {
          openSnackbar('Invalid Connection; Is you SSD selected first?');
          return;
        }
        const newLocation = onGenerateEquipment(cableType, { ssd_id: finSSD.locationid, lsd_id: finLSD.locationid })

        if (!newLocation) {
          return;
        }

        LocationArray.add(newLocation);
        reRenderLocationArray();
      }

    } else if (selectedTab === 3) {
      // Alignment
      // do nothing when clicking nothing
    }
  }

  function handleSaveAlignment(event) {

    const onSaveSuccess = () => {
      setDialogOpen(false);
      openSnackbar('Alignment Saved Successfully');

      // make sure to replace the alignments.  This will set up the alignment id's
    }


    handleOpenDialog(
      <ConfirmDialog
        title="Save Alignments"
        message="The alignment will be saved to the database and overwrite the previous alignment.
                Are you sure you want to save?  Note that any newly created locations will not be saved."
        onConfirm={() => {
          // handleProgress(event.target.className)
          handleSaveAlignmentArray(onSaveSuccess);
        }}
        onCancel={() => { setDialogOpen(false) }}
      />
    );
  }

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };


  const handleOpenPoleConnectionManager = () => {
    handleOpenDialog(<PoleConnectionManager
        onGenerateEquipment= { onGenerateEquipment } 
        defaultEquipmentList={defaultEquipmentList}
      />
    );
  }

  function handleSelected(newSelectedObjects) {
    // always create a new array to trigger useEffects
    setSelectedObjects([...newSelectedObjects]);

    // Specific Functionality for each tab
    if (selectedTab === 0) {
    } else if (selectedTab === 1) {
    } else if (selectedTab === 2) {
    } else if (selectedTab === 3) {
    }
  }

  function handleSelectIcon(iconName) {

    if (!editMode) {
      setSelectedIcon(null);
      openSnackbar('edit mode is off')
      return;
    }

    // const newEquip = onGenerateEquipment(iconName);

    setSelectedIcon(iconName);
  }

  const handleDeselectObjects = () => { setSelectedObjects([]); }

  const handleSwapDevices = (ev, location) => {
    LocationArray.update(location.locationid, {
      ssd_id: location.lsd_id,
      lsd_id: location.ssd_id,
    })
    reRenderLocationArray();
  }

  const handleFlyTo = (lat, lng, zoomLevel = 16) => {
    if (mapRef.current) {
      mapRef.current.flyTo([lat, lng], zoomLevel);
    }
  };


  return (
    <Box className="equipment-mapper-container" sx={{ border: '1px solid black', display: 'flex', flexDirection: 'row' }}>
      <Dialog maxWidth="lg" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box className="dialog-content" sx={{ p: 4 }}>{dialogContent}</Box>
      </Dialog>

      <Box className="tutorial-equipment-mapper-tabs equipment-mapper-tabs" sx={{ border: '1px solid black', overflowY: 'auto', maxHeight: '50vh' }}>
        <Tabs value={selectedTab} onChange={handleTabChange}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': { flexWrap: 'wrap', },
            border: '1px solid black',
          }}>
          <Tab label="Move Equipment" sx={{ boxShadow: selectedTab === 0 ? '0px 0px 5px 2px rgba(0, 0, 0, 0.2)' : 'none' }} />
          <Tab label="Place Equipment" sx={{ boxShadow: selectedTab === 1 ? '0px 0px 5px 2px rgba(0, 0, 0, 0.2)' : 'none' }} />
          <Tab label="Connection" sx={{ boxShadow: selectedTab === 2 ? '0px 0px 5px 2px rgba(0, 0, 0, 0.2)' : 'none' }} />
          <Tab label="Alignment" sx={{ boxShadow: selectedTab === 3 ? '0px 0px 5px 2px rgba(0, 0, 0, 0.2)' : 'none' }} />
        </Tabs>

        {selectedTab === 0 && (
          <Box className="equipment-mapper-loc-placer-tab" color="primary" sx={{ minWidth: '200px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label={<Typography className="equipment-mapper-edit-mode-label" sx={{ fontSize: '1.2rem' }}>Place or Move Existing Equipment</Typography>}
                control={
                  <Checkbox
                    checked={editMode}
                    onChange={() => setEditMode(!editMode)}
                  />
                }
              />
            </Box>
            <EquipmentPlacer className="equipment-mapper-loc-placer"
              LocationArray={LocationArray}
              selectedObjects={selectedObjects}
              setSelectedObjects={setSelectedObjects}
            />
          </Box>
        )}

        {selectedTab === 1 && (
          <Box className="equipment-mapper-icon-picker-tab" sx={{ minWidth: '200px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label={<Typography className="equipment-mapper-edit-mode-label" sx={{ fontSize: '1.2rem' }}>Place New Equipment</Typography>}
                control={
                  <Checkbox
                    checked={editMode}
                    onChange={() => { setSelectedIcon(null); setEditMode(!editMode) }}
                  />
                }
              />
            </Box>
            <IconPicker className="equipment-mapper-icon-picker"
              selectedIcon={selectedIcon}
              onSelectIcon={handleSelectIcon}
            />
          </Box>
        )}

        {selectedTab === 2 && (
          <Box className="equipment-mapper-connection-maker-tab" sx={{ minWidth: '200px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label={<Typography className="equipment-mapper-edit-mode-label" sx={{ fontSize: '1.2rem' }}>Create New Connections</Typography>}
                control={
                  <Checkbox
                    checked={editMode}
                    onChange={() => setEditMode(!editMode)}
                  />
                }
              />
            </Box>
            <ConnectionMaker className="equipment-mapper-connection-maker"
              LocationArray={LocationArray}
              selectedObjects={selectedObjects}
              onSwapDevices={handleSwapDevices}
              handleFlyTo={handleFlyTo}
              handleOpenPoleConnectionManager={handleOpenPoleConnectionManager}
            />
          </Box>
        )}

        {selectedTab === 3 && (
          <Box className="equipment-mapper-alignment-maker-tab" sx={{ minWidth: '200px' }}>
            <AlignmentMaker
              className="equipment-mapper-alignment-maker"
              alignment={alignmentArray}
              setAlignment={setAlignmentArray}
              LocationArray={LocationArray}
              selectedObjects={selectedObjects}
              onDeselectObjects={handleDeselectObjects}
              handleOpenDialog={handleOpenDialog}
              mapRef={mapRef}
            />
          </Box>
        )}
      </Box>

      <MyMapContainer
        mapRef={mapRef}
        LocationArray={LocationArray}
        reRenderLocationArray={reRenderLocationArray}
        selectedObjects={selectedObjects}
        setSelectedObjects={setSelectedObjects}
        handleSelected={handleSelected}
        alignments={alignmentArray}
        setAlignments={setAlignmentArray}
        handleSaveAlignment={handleSaveAlignment}
        editMode={editMode}
        onGenerateEquipment={onGenerateEquipment}
        handleOpenDialog={handleOpenDialog}
        onMapClickInTab={handleMapClickInTab}
      />
      <JoyRideStarter
        className="tutorial-locations-map-joyride-overview"
        onStart={() => { handleProgress("tutorial-locations-map-joyride-overview") }}
        steps={steps}
        width={0}
        anchorVert={-8}
        disableScrolling={true}
      />
    </Box>
  );
}

export default EquipmentMapper;
