import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { UseServerData, PostServerData, DeleteServerData } from "../../util/dbfetch";

import useUserDataContext from '../../util/providers/userDataProvider.jsx';

import ConfirmDialog from "../../components/confirmDialog";
import useSnackbarContext from "../../util/providers/snackbarProvider";

import TransferResponsibilityOrganization from "../../components/transfers/transferResponsibilityOrganization";


export default function OrganizationManagerView({
  organization, users,
  activeProjects,
  onOpenDialog, onCloseDialog,
  isManagerVerified, setIsManagerVerified
}) {
  const navigate = useNavigate();
  const { orgID } = useParams();
  const [manager, setManager] = useState(false)

  const [selectedNewManager, setSelectedNewManager] = useState(null);

  const [selectedWorkstreams, setSelectedWorkstreams] = useState([]);
  const allWorkstreams = [...organization.workstreams, ...organization.workstreams_workable];
  const uniqueWorkstreams = Array.from(new Set(allWorkstreams.map(w => w.utility)))
    .map(utility => {
      return allWorkstreams.find(w => w.utility === utility)
    });

  const { refreshData } = useUserDataContext();

  const { openSnackbar } = useSnackbarContext();

  const handledDeletedOrganization = () => {
    onCloseDialog();
    navigate('/organizations');
    openSnackbar("Organization deleted");
  }



  useEffect(() => {
    if (organization && users) {
      setManager(users?.find(user => user.userid.toString() === organization?.managerid.toString()))
      let man = users?.find(user => user.userid.toString() === organization?.managerid.toString())
      setSelectedNewManager(man)

      setSelectedWorkstreams(organization?.workstreams)
    }

  }, [organization, users])

  useEffect(() => {
    if (orgID) {
      UseServerData(`organizations/${orgID}/verify-manager`,
        resp => setIsManagerVerified(resp), () => { }, () => setIsManagerVerified(false),);
    }
  }, [orgID, setIsManagerVerified])


  const handleTransferManager = () => {
    onOpenDialog(
      <ConfirmDialog
        title="Transfer Management"
        message="Are you sure you want to transfer the management of this organization?"
        onConfirm={() => {
          PostServerData(`organizations/${orgID}/transfer-management`, selectedNewManager, () => window.location.reload(), (resp) => openSnackbar(resp));
          onCloseDialog();
          openSnackbar("Organization successfully transferred.");
        }}
        onCancel={() => onCloseDialog()}
      />
    );
  };


  const handleWorkstreamChange = (event) => {
    if (!isManagerVerified) {
      return;
    }
    const selectedValues = event.target.value;
    const newSelectedWorkstreams = selectedValues.map((utility) => {
      return uniqueWorkstreams.find((workstream) => workstream.utility === utility);
    });
    setSelectedWorkstreams(newSelectedWorkstreams);
  };

  const handleWorkstreamSubmit = () => {
    if (selectedWorkstreams.length === 0) {
      onOpenDialog(
        <ConfirmDialog
          title="No Workstreams Selected"
          message="You have not selected any workstreams. This means new potential clients will not be able to request quotes from your organization. Are you sure you want to continue?"
          onConfirm={() => {
            PostServerData(`organizations/${orgID}/update-workstreams`, selectedWorkstreams, () => { }, (resp) => openSnackbar(resp));
            refreshData();
            onCloseDialog();
          }}
          onCancel={() => onCloseDialog()}
        />
      );
    } else {
      onOpenDialog(
        <ConfirmDialog
          title="Update Workstreams"
          message="New potential clients on this platform will be able to request quotes from your organization for these selected workstreams. Are you sure you want continue?"
          onConfirm={() => {
            PostServerData(`organizations/${orgID}/update-workstreams`, selectedWorkstreams, () => { }, (resp) => openSnackbar(resp));
            refreshData();
            onCloseDialog();
          }}
          onCancel={() => onCloseDialog()}
        />
      );
    }
  };

  function handleDeleteOrganization(organizationid) {
    onOpenDialog(
      <ConfirmDialog
        title="Delete Organization"
        message="Are you sure you want to delete this organization?"
        onConfirm={() => {
          DeleteServerData(
            `organizations/delete/${organizationid}`,
            handledDeletedOrganization,
            (err) => openSnackbar(err)
          );
          refreshData();
        }}
        onCancel={() => onCloseDialog()}
      />
    );
  }


  return (
    <Paper className="tutorial-org-overview manager-view" sx={{ mb: 4, border: 1 }}>
      {manager && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Managed By: {manager.username}</Typography>
              {isManagerVerified ? (
                <Icon className="manager-icon" title="You are the Manager"> <AdminPanelSettingsIcon /></Icon>
              ) : (
                null
              )}
            </AccordionSummary>
            {isManagerVerified && (
              <AccordionDetails>
                <Typography variant="h1">Protected Information</Typography>
                <Box className="organization-workstream-change" sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                    <InputLabel id="workstream-select-label">Accepting Workstreams</InputLabel>
                    <Select
                      labelId="workstream-select-label"
                      label="Accepting WorkstreamsBuffBuffer"
                      id="workstream-select"
                      multiple
                      value={selectedWorkstreams.map((workstream) => workstream.utility)}
                      onChange={handleWorkstreamChange}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {uniqueWorkstreams.map((workstream) => (
                        <MenuItem key={workstream.workstreamid} value={workstream.utility}
                          disabled={!organization.workstreams_workable.some(w => w.utility === workstream.utility)}>
                          <ListItemText primary={workstream.utility} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button variant="contained" disabled={isManagerVerified ? false : true} onClick={handleWorkstreamSubmit}>
                    Save
                  </Button>
                </Box>
                <Tooltip className="active-tooltip" placement='top-start' title="Projects are active when changed in a billing cycle">
                  <Typography>Active/Inactive Projects : {activeProjects}/{organization.projects_allowed}</Typography>
                </Tooltip>

                {manager && (
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start', justifyContent: 'space-between', gap: 2 }}>
                    <Box className="leftContainer">
                      <Button
                        variant="contained"
                        className="generate-invite-button"
                        onClick={() => onOpenDialog(
                          <TransferResponsibilityOrganization
                            organization={organization}
                          />
                        )}
                      >
                        Generate Organization Invite
                      </Button>
                      <Box sx={{ my: 2 }}>
                        <Typography>Transfer Management to:</Typography>
                        <Select
                          labelId="user-select-label"
                          id="user-select"
                          value={selectedNewManager ? selectedNewManager.username : ''}
                          onChange={(event) => setSelectedNewManager(event.target.value)}
                        >
                          {users.map((user) => (
                            <MenuItem key={user.userid} value={user.username}>
                              {user.username}
                            </MenuItem>
                          ))}
                        </Select>
                        <Button variant="contained" onClick={handleTransferManager}>Transfer</Button>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                      <Button variant="outlined" className="delete-button" onClick={() => handleDeleteOrganization(orgID)}>Delete Organization</Button>
                    </Box>
                  </Box>
                )}
              </AccordionDetails>
            )}
          </Accordion>
        </>
      )}
    </Paper>
  )
}