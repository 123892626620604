import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import { useTutorial } from '../../util/providers/useTutorialContext';
import { PostServerData } from '../../util/dbfetch';

export default function Register({ onRegister, onClose }) {
  // const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [transferCode, setTransferCode] = useState('');
  const [response, setResponse] = useState('init');
  const [modalOpen, setModalOpen] = useState(false);

  const { handleStartTutorial } = useTutorial();

  function handleRegisterFailure(response) {
    setResponse(`${response?.data?.detail}` || `${response?.data?.message}` ||
      `There was a problem registering an account.  \n 
      Double check your invite code and try again \n 
      If you continue to have problems, contact the website admin.`
    );
    setModalOpen(true);
  }


  function handleRegisterSuccess(response) {
    setResponse(`${response?.data?.message} || "Account created successfully!`);
    setModalOpen(true);
    // start tutorial
    handleStartTutorial("initialize-tutorial");
    onRegister();
  }

  function handleRegisterRequest() {
    if (password !== confirmPassword) {
      setResponse('Passwords do not match');
      setModalOpen(true);
      return;
    }

    setResponse('Requesting...');
    PostServerData('register', { username: username, password: password, transfer_code: transferCode },
      handleRegisterSuccess, handleRegisterFailure)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleRegisterRequest();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setResponse('');
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form>
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <br />
        <br />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <TextField
          id="confirm-password"
          label="Confirm Password"
          variant="outlined"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <TextField
          id="invite-code"
          label="Invite Code"
          variant="outlined"
          value={transferCode}
          onChange={(e) => setTransferCode(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <br />
        <br />
        <Button variant="contained" onClick={handleRegisterRequest}>
          Register
        </Button>
        <br />
        <br />
      </form>
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{ p: 4 }}>
          <Typography variant="body2">{response}</Typography>
        </Box>
      </Dialog>
    </>
  );
}